/**
 * Created by PanJiaChen on 16/11/18.
 */
import * as math from 'mathjs';
/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string}
 */
export function parseTime(time, cFormat) {
  if (arguments.length === 0) {
    return null
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if ((typeof time === 'string') && (/^[0-9]+$/.test(time))) {
      time = parseInt(time)
    }
    if ((typeof time === 'number') && (time.toString().length === 10)) {
      time = time * 1000
    }
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
    let value = formatObj[key]
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') { return ['日', '一', '二', '三', '四', '五', '六'][value] }
    if (result.length > 0 && value < 10) {
      value = '0' + value
    }
    return value || 0
  })
  return time_str
}

/**
 * @param {number} time
 * @param {string} option
 * @returns {string}
 */
export function formatTime(time, option) {
  if (('' + time).length === 10) {
    time = parseInt(time) * 1000
  } else {
    time = +time
  }
  const d = new Date(time)
  const now = Date.now()

  const diff = (now - d) / 1000

  if (diff < 30) {
    return '刚刚'
  } else if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + '分钟前'
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + '小时前'
  } else if (diff < 3600 * 24 * 2) {
    return '1天前'
  }
  if (option) {
    return parseTime(time, option)
  } else {
    return (
      d.getMonth() +
      1 +
      '月' +
      d.getDate() +
      '日' +
      d.getHours() +
      '时' +
      d.getMinutes() +
      '分'
    )
  }
}

/**
 * @param {string} url
 * @returns {Object}
 */
export function param2Obj(url) {
  const search = url.split('?')[1]
  if (!search) {
    return {}
  }
  return JSON.parse(
    '{"' +
      decodeURIComponent(search)
        .replace(/"/g, '\\"')
        .replace(/&/g, '","')
        .replace(/=/g, '":"')
        .replace(/\+/g, ' ') +
      '"}'
  )
}

import BN from 'bn.js'
import Web3 from 'web3'

export function fromWei(wei, decimals) {
    var num = new BN(wei, 10).mul(new BN(10).pow(new BN(18 - decimals)));
    return Web3.utils.fromWei(num, 'ether');
}

export function toWei(n, decimals) {
    var wei = new BN(Web3.utils.toWei(n, 'ether'), 10)
    return wei.div(new BN(10).pow(new BN(18 - decimals)));
}

export function showFromWei(wei, decimals,dots) {
    let num = fromWei(wei,decimals);
    let index = num.indexOf('.');
    if (index !== -1) {
        num = num.substring(0, dots + index + 1)
    } else {
        num = num.substring(0)
    }
    if (num.endsWith('.')) {
        num = num.substring(0, num.length - 1);
    }
    return num;
}
export function showAccount(account) {
    if (account) {
        return account.substring(0, 15) + "..." + account.substr(-15);
    }
    return "";
}
export function showAccount2(account) {
    if (account) {
        return account.substring(0, 10) + "..." + account.substr(-10);
    }
    return "";
}
export function showAccount3(account) {
    if (account) {
        return account.substring(0, 5) + "..." + account.substr(-4);
    }
    return "";
}
export function showAccount4(account) {
    if (account) {
        return account.substring(0, 3) + "..." + account.substr(-4);
    }
    return "";
}
export function showFromWei2(num,dots) {
  num = num + ""
  let index = num.indexOf('.');
  if (index !== -1) {
      num = num.substring(0, dots + index + 1)
  } else {
      num = num.substring(0)
  }
  if (num.endsWith('.')) {
      num = num.substring(0, num.length - 1);
  }
  return num;
}
export function fromWeiMore(wei, decimals) {
    if (!wei) {
        return "0";
    }
    if (decimals <= 18) {
        let num = new BN(wei, 10).mul(new BN(10).pow(new BN(18 - decimals)));
        return Web3.utils.fromWei(num, 'ether');
    } else {
        let num = new BN(wei, 10).mul(new BN(10).pow(new BN(30 - decimals)));
        return Web3.utils.fromWei(num, 'tether');
    }
}
//显示价格使用这个
export function showFromWeiMore(wei, decimals, dots) {
    let num = fromWeiMore(wei, decimals);
    let index = num.indexOf('.');
    if (index !== -1) {
        num = num.substring(0, dots + index + 1)
    } else {
        num = num.substring(0)
    }
    if (num.endsWith('.')) {
        num = num.substring(0, num.length - 1);
    }
    return num;
}
//价格转换为BN，用于比较大小
export function toWeiMore(n) {
    if (!n) {
        return new BN(0);
    }
    let wei = new BN(Web3.utils.toWei(n, 'tether'), 10);
    return wei;
}
export const formatDecimal = function (num,decimal = 6) {
  return math.round(num, decimal)
}
export function multiply(num1,num2) {
		return math.multiply(math.bignumber(num1),math.bignumber(num2));
	}
export const clickOutside = {
  bind: function(el, binding, vNode) {
    el.__vueClickOutside__ = event => {
      if (!el.contains(event.target)) {
        // call method provided in v-click-outside value
        vNode.context[binding.expression](event);
        event.stopPropagation();
      }
    };
    document.body.addEventListener('click', el.__vueClickOutside__);
  },
  unbind: function(el, binding, vNode) {
    // Remove Event Listeners
    document.removeEventListener('click', el.__vueClickOutside__);
    el.__vueClickOutside__ = null;
  },
};
