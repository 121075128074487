export const chainObj = {
  Ethereum: {
    chain: "Ethereum",
    ChainId: 1,
    chainName: "Ethereum",
    gasMulti: 11,
    Symbol: "ETH",
    logo: require("./assets/img/eth.png"),
    RPC: "https://ethereum.publicnode.com",
    Browser: "https://etherscan.io/",
    USDT: "0xdAC17F958D2ee523a2206206994597C13D831ec7",
    WETH: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
    Tokens: [{
        Symbol: "ETH",
        name: "Ethereum",
        logo: require("./assets/img/eth2.png"),
        address: "",
        decimals: 18,
      },
      {
        Symbol: "USDT",
        name: "Tether",
        logo: require("./assets/img/usdt.png"),
        address: "0xdAC17F958D2ee523a2206206994597C13D831ec7",
        decimals: 6,
      },
      {
        Symbol: "USDC",
        name: "USD Coin",
        logo: require("./assets/img/usdc.png"),
        address: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
        decimals: 6,
      },
      {
        Symbol: "DAI",
        name: "Dai Stablecoin",
        logo: require("./assets/img/dai.png"),
        address: "0x6B175474E89094C44Da98b954EedeAC495271d0F",
        decimals: 18,
      },
    ],
    Dexs: [{
        name: "UniSwapV2",
        chain: "Ethereum",
        SwapRouter: "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D",
        logo: require("./assets/img/uni.png"),
      },
      {
        name: "SushiSwap",
        chain: "Ethereum",
        SwapRouter: "0xd9e1cE17f2641f24aE83637ab66a2cca9C378B9F",
        logo: require("./assets/img/su.png"),
      },
    ],
    Common: "0x1453027045D7545260e309A82f48b123c32f5838",
    MultiSend: '0xd85495924f70046723Ab6BE850a22B15dF9b2c53',
  },
  BSC: {
    chain: "BSC",
    ChainId: 56,
    chainName: "Binance Smart Chain",
    gasMulti: 7,
    Symbol: "BNB",
    logo: require("./assets/img/bnbcoin.png"),
    RPC: "https://bsc-dataseed1.binance.org/",
    Browser: "https://bscscan.com/",
    USDT: "0x55d398326f99059fF775485246999027B3197955",
    WETH: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
    Tokens: [{
        Symbol: "BNB",
        name: "BNB",
        logo: require("./assets/img/bsc.png"),
        address: "",
        decimals: 18,
      },
      {
        Symbol: "USDT",
        name: "Tether",
        logo: require("./assets/img/usdt.png"),
        address: "0x55d398326f99059fF775485246999027B3197955",
        decimals: 18,
      },
      {
        Symbol: "BUSD",
        name: "Binance USD",
        logo: require("./assets/img/bnbusdt.png"),
        address: "0xe9e7cea3dedca5984780bafc599bd69add087d56",
        decimals: 18,
      },
    ],
    Dexs: [{
      name: "PancakeSwap",
      chain: "BSC",
      SwapRouter: "0x10ED43C718714eb63d5aA57B78B54704E256024E",
      logo: require("./assets/img/ex1.png"),
    },
    {
        name: "Sushi",
        chain: "BSC",
        SwapRouter: "0x1b02da8cb0d097eb8d57a175b88c7d8b47997506",
        logo: require("./assets/img/su.png"),
      },
      {
        name: "Biswap",
        chain: "BSC",
        SwapRouter: "0x3a6d8ca21d1cf76f653a67577fa0d27453350dd8",
        logo: require("./assets/img/biswap.png"),
      }
    ],
    Common: "0x4b860FCC0B41283132f4792AD91038DC211831C1",
    MultiSend: '0xBE9f4f99E4FE188428188763EcD53715d713D226',
  },
  "ARB": {
    chain: "ARB",
    ChainId: 42161,
    chainName: "Arbitrum One",
    gasMulti: 26,
    Symbol: "ETH",
    logo: require("./assets/img/arb.png"),
    RPC: 'https://rpc.ankr.com/arbitrum',
    Browser: 'https://arbiscan.io/',
    USDT: '0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9',
    WETH: '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1',
    Tokens: [{
        Symbol: "ETH",
        name: "ETH",
        logo: require("./assets/img/eth_arb.png"),
        address: "",
        decimals: 18,
      },
      {
        Symbol: "USDT",
        name: "Tether",
        logo: require("./assets/img/usdt.png"),
        address: "0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9",
        decimals: 18,
      },
      {
        Symbol: "USDC",
        name: "USD Coin",
        logo: require("./assets/img/usdc_arb.png"),
        address: "0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8",
        decimals: 18,
      },
      {
        Symbol: "DAI",
        name: "Dai Stablecoin",
        logo: require("./assets/img/dai.png"),
        address: "0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1",
        decimals: 18,
      },
    ],
    Dexs: [
    //   {
    //   name: "CAMELOT",
    //   chain: "Arbitrum One",
    //   SwapRouter: "0xc873fEcbd354f5A56E00E710B90EF4201db2448d",
    //   logo: require("./assets/img/CAMELOT.png"),
    // },
    {
      name: "Sushi",
      chain: "Arbitrum One",
      SwapRouter: "0x1b02da8cb0d097eb8d57a175b88c7d8b47997506",
      logo: require("./assets/img/su.png"),
    }
    ],
    Common: "0xFE6133e86fb4d5811f2e6881c2E25567B45b60B6",
    MultiSend: '0x548a0260d6329a7aeEc282D95936bD8371358Cf6',
  },
  "OP": {
    chain: "OP",
    ChainId: 10,
    chainName: "Optimism",
    gasMulti: 26,
    Symbol: "ETH",
    logo: require("./assets/img/Optimism.png"),
    RPC: 'https://mainnet.optimism.io',
    Browser: 'https://arbiscan.io/',
    USDT: '0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9',
    WETH: '0x4200000000000000000000000000000000000006',
    Tokens: [{
        Symbol: "ETH",
        name: "ETH",
        logo: require("./assets/img/eth_arb.png"),
        address: "",
        decimals: 18,
      },
      {
        Symbol: "USDT",
        name: "Tether",
        logo: require("./assets/img/usdt.png"),
        address: "0x94b008aA00579c1307B0EF2c499aD98a8ce58e58",
        decimals: 18,
      },
      {
        Symbol: "USDC",
        name: "USD Coin",
        logo: require("./assets/img/usdc_arb.png"),
        address: "0x7F5c764cBc14f9669B88837ca1490cCa17c31607",
        decimals: 18,
      },
      {
        Symbol: "DAI",
        name: "Dai Stablecoin",
        logo: require("./assets/img/dai.png"),
        address: "0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1",
        decimals: 18,
      },
    ],
    Dexs: [
      {
      name: "Velodrome",
      chain: "Optimism",
      SwapRouter: "0x9c12939390052919aF3155f41Bf4160Fd3666A6f",
      logo: require("./assets/img/Velodrome.png"),
    }
    ],
    Common: "0xFE6133e86fb4d5811f2e6881c2E25567B45b60B6",
    MultiSend: '0x548a0260d6329a7aeEc282D95936bD8371358Cf6',
  },
  "Polygon": {
    chain: "Polygon",
    ChainId: 137,
    chainName: "Polygon",
    gasMulti: 26,
    Symbol: "Matic",
    logo: require("./assets/img/Polygon.png"),
     RPC: 'https://polygon.llamarpc.com',
    Browser: 'https://arbiscan.io/',
    USDT: '0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9',
    WETH: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
    Tokens: [{
        Symbol: "Matic",
        name: "Matic",
        logo: require("./assets/img/Polygon.png"),
        address: "",
        decimals: 18,
      },
      {
        Symbol: "USDT",
        name: "Tether",
        logo: require("./assets/img/usdt.png"),
        address: "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
        decimals: 18,
      },
      {
        Symbol: "USDC",
        name: "USD Coin",
        logo: require("./assets/img/usdc_arb.png"),
        address: "0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174",
        decimals: 18,
      },
      {
        Symbol: "DAI",
        name: "Dai Stablecoin",
        logo: require("./assets/img/dai.png"),
        address: "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
        decimals: 18,
      },
    ],
    Dexs: [
      {
      name: "QuickSwap",
      chain: "Polygon",
      SwapRouter: "0xa5e0829caced8ffdd4de3c43696c57f7d7a678ff",
      logo: require("./assets/img/QuickSwap.png"),
    },
    {
      name: "Sushi",
      chain: "Polygon",
      SwapRouter: "0x1b02dA8Cb0d097eB8D57A175b88c7D8b47997506",
      logo: require("./assets/img/su.png"),
    }
    ],
    Common: "0xC1c5e241dC5F648AB0F66F50121E5aA8548b4fcd",
    MultiSend: '0x52869Bfd715bE279c0312057B1C33e902A3044F0',
  },
  "Fantom": {
    chain: "Fantom",
    ChainId: 250,
    chainName: "Fantom Opera",
    gasMulti: 26,
    Symbol: "FTM",
    logo: require("./assets/img/Fantom.png"),
    RPC: 'https://rpc.ankr.com/fantom',
    Browser: 'https://ftmscan.com/',
    USDC: '0x04068DA6C83AFCFA0e13ba15A6696662335D5B75',
    WETH: '0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83',
    Tokens: [{
        Symbol: "FTM",
        name: "Fantom",
        logo: require("./assets/img/Fantom.png"),
        address: "",
        decimals: 18,
      },
      {
        Symbol: "USDC",
        name: "USD Coin",
        logo: require("./assets/img/usdc_arb.png"),
        address: "0x04068DA6C83AFCFA0e13ba15A6696662335D5B75",
        decimals: 18,
      },
      {
        Symbol: "DAI",
        name: "Dai Stablecoin",
        logo: require("./assets/img/dai.png"),
        address: "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
        decimals: 18,
      },
    ],
    Dexs: [
      {
      name: "SpookySwap",
      chain: "Fantom",
      SwapRouter: "0x31F63A33141fFee63D4B26755430a390ACdD8a4d",
      logo: require("./assets/img/SpookySwap.png"),
    }
    ],
    Common: "0x548a0260d6329a7aeEc282D95936bD8371358Cf6",
    MultiSend: '0xC1c5e241dC5F648AB0F66F50121E5aA8548b4fcd',
  },
  "OKC": {
    chain: "OKC",
    ChainId: 66,
    chainName: "OKX Chain",
    gasMulti: 1,
    Symbol: "OKT",
    logo: require("./assets/img/OKchain.png"),
    RPC: 'https://exchainrpc.okex.org',
    Browser: 'https://www.oklink.com/cn/okc',
    USDT: '0x382bb369d343125bfb2117af9c149795c6c65c50',
    WETH: '0x8f8526dbfd6e38e3d8307702ca8469bae6c56c15',
    Tokens: [{
        Symbol: "OKT",
        name: "OKT Token",
        logo: require("./assets/img/OKchain.png"),
        address: "",
        decimals: 18,
      },
      {
        Symbol: "USDT",
        name: "Tether",
        logo: require("./assets/img/usdt.png"),
        address: "0x382bb369d343125bfb2117af9c149795c6c65c5",
        decimals: 18,
      },
      {
        Symbol: "USDC",
        name: "USD Coin",
        logo: require("./assets/img/usdc_arb.png"),
        address: "0xc946daf81b08146b1c7a8da2a851ddf2b3eaaf85",
        decimals: 18,
      },
      
    ],
    Dexs: [
      {
      name: "OKCSwap",
      chain: "OKX Chain",
      SwapRouter: "0xc97b81B8a38b9146010Df85f1Ac714aFE1554343",
      logo: require("./assets/img/OKchain.png"),
    }
    ],
    Common: "0xCd55940bD435ca24A23e141c096f87aC1d4689A0",
    MultiSend: '0x84E0D301DCEE290f5951376A5b98f19DbD3aC570',
  },
  "CORE": {
    chain: "CORE",
    ChainId: 116,
    chainName: "Core Dao",
    gasMulti: 2,
    Symbol: "CORE",
    logo: require("./assets/img/CORE.png"),
    RPC: 'https://rpc.coredao.org/',
    Browser: 'https://scan.coredao.org/',
    USDT: '0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9',
    WETH: '0x000000000e1d682cc39abe9b32285fdea1255374',
    Tokens: [{
        Symbol: "CORE",
        name: "Core Dao",
        logo: require("./assets/img/CORE.png"),
        address: "",
        decimals: 18,
      },
      {
        Symbol: "USDT",
        name: "Tether",
        logo: require("./assets/img/usdt.png"),
        address: "0x9ebab27608bd64aff36f027049aecc69102a0d1e",
        decimals: 6,
      },
      {
        Symbol: "USDC",
        name: "USD Coin",
        logo: require("./assets/img/usdc_arb.png"),
        address: "0x7F5c764cBc14f9669B88837ca1490cCa17c31607",
        decimals: 18,
      },
      
    ],
    Dexs: [
      {
      name: "LFGSwap",
      chain: "Core Dao",
      SwapRouter: "0x42a0F91973536f85B06B310fa9C70215784F35a1",
      logo: require("./assets/img/lfgswap.png"),
    },
    {
      name: "ShadowSwap",
      chain: "Core Dao",
      SwapRouter: "0xCCED48E6fe655E5F28e8C4e56514276ba8b34C09",
      logo: require("./assets/img/ShadowSwap.png"),
    }
    ],
    Common: "0xFE6133e86fb4d5811f2e6881c2E25567B45b60B6",
    MultiSend: '0x548a0260d6329a7aeEc282D95936bD8371358Cf6',
  },
  //ETHW链配置信息
  EthereumPoW: {
    chain: 'EthereumPoW',
    ChainId: 10001,
    chainName: "ETHW",
    gasMulti: 11,
    Symbol: 'ETHW',
    logo: require("./assets/img/ethw.png"),
    RPC: 'https://mainnet.ethereumpow.org/',
    Browser: 'https://www.oklink.com/en/ethw/',
    USDT: '0x2AD7868CA212135C6119FD7AD1Ce51CFc5702892',
    WETH: '0x7Bf88d2c0e32dE92CdaF2D43CcDc23e8Edfd5990',
    Tokens: [{
      Symbol: 'ETHW',
      name: "EthereumPoW",
      address: "",
      logo: require("./assets/img/ethw.png"),
      decimals: 18,
    }, {
      Symbol: 'USDT',
      name: "Tether",
      address: "0x2AD7868CA212135C6119FD7AD1Ce51CFc5702892",
      logo: require("./assets/img/usdt.png"),
      decimals: 6,
    }],
    Dexs: [{
      name: 'LFGSwap',
      chain: "ETHW",
      SwapRouter: '0x4f381d5fF61ad1D0eC355fEd2Ac4000eA1e67854',
      logo: require("./assets/img/lfgswap.png"),
    }],
    Common: '0x1453027045D7545260e309A82f48b123c32f5838',
    MultiSend: '0x59886a35796e33890dEc08D3468F6C8DCfEa4ea9',
  },
  FON: {
    chain: 'FON',
    ChainId: 201022,
    chainName: "FON",
    gasMulti: 7,
    Symbol: 'FON',
    logo: require("./assets/img/fon.png"),
    RPC: 'https://fsc-dataseed2.fonscan.io/',
    Browser: 'https://fonscan.io/',
    USDT: '0x9a9eD7440a3850c4D7240c9FcA8B7C96802615f0',
    WETH: '0xb582fD9d0D5C3515EEB6b02fF2d6eE0b6E45E7A7',
    Tokens: [{
      Symbol: 'FON',
      name: "Fonvity Chain",
      address: "",
      logo: require("./assets/img/fon.png"),
      decimals: 18,
    }, {
      Symbol: 'WFON',
      name: "Fonvity Chain",
      address: "0xb582fD9d0D5C3515EEB6b02fF2d6eE0b6E45E7A7",
      logo: require("./assets/img/fon.png"),
      decimals: 18,
    }, {
      Symbol: 'USDT',
      name: "Tether",
      address: "0x9a9eD7440a3850c4D7240c9FcA8B7C96802615f0",
      logo: require("./assets/img/usdt.png"),
      decimals: 6,
    }],
    Dexs: [{
      name: 'rosswap',
      chain: "FON",
      SwapRouter: '0x5Df615972954257133d7A0d5fFD68CddD31033d2',
      logo: require("./assets/img/rosswap.png"),
    }],
    Common: '0x1453027045D7545260e309A82f48b123c32f5838',
    MultiSend: '0x4b860FCC0B41283132f4792AD91038DC211831C1',
  },
  Heco: {
    chain: "Heco",
    ChainId: 128,
    chainName: "Huobi ECO Chain", //"Huobi ECO Chain Mainnet",
    gasMulti: 3,
    Symbol: "HT",
    logo: require("./assets/img/ht.png"),
    RPC: "https://http-mainnet.hecochain.com/",
    Browser: "https://www.hecoinfo.com/en-us/",
    USDT: "0xa71EdC38d189767582C38A3145b5873052c3e47a",
    WETH: "0x5545153CCFcA01fbd7Dd11C0b23ba694D9509A6F",
    Tokens: [{
        Symbol: "HT",
        name: "Huobi Token",
        logo: require("./assets/img/ht.png"),
        address: "",
        decimals: 18,
      },
      {
        Symbol: "USDT",
        name: "Tether",
        logo: require("./assets/img/usdt.png"),
        address: "0xa71EdC38d189767582C38A3145b5873052c3e47a",
        decimals: 18,
      },
      // {
      //   Symbol: "HUSD",
      //   name: "HUSD",
      //   logo: require("./assets/img/husd.png"),
      //   address: "0x0298c2b32eaE4da002a15f36fdf7615BEa3DA047",
      //   decimals: 8,
      // },
    ],
    Dexs: [{
        name: "MDEX",
        chain: "Heco",
        SwapRouter: "0x0f1c2D1FDD202768A4bDa7A38EB0377BD58d278E",
        logo: require("./assets/img/ex3.png"),
      },
      {
        name: "PIPPI",
        chain: "Heco",
        SwapRouter: "0xBe4AB2603140F134869cb32aB4BC56d762Ae900B",
        logo: require("./assets/img/ex2.png"),
      },
    ],
    Common: "0x5e43d6dBdF6CEa7dbdBfF21168f1C8fCcF57161B",
    MultiSend: '0x1453027045D7545260e309A82f48b123c32f5838',
  },
}
export const chainObj2 = {
  Ethereum: {
    chain: "Ethereum",
    ChainId: 1,
    chainName: "Ethereum",
    gasMulti: 11,
    Symbol: "ETH",
    logo: require("./assets/img/eth.png"),
    RPC: "https://ethereum.publicnode.com",
    Browser: "https://etherscan.io/",
    USDT: "0xdAC17F958D2ee523a2206206994597C13D831ec7",
    WETH: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
    Tokens: [{
        Symbol: "ETH",
        name: "Ethereum",
        logo: require("./assets/img/eth2.png"),
        address: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
        decimals: 18,
      },
      {
        Symbol: "USDT",
        name: "Tether",
        logo: require("./assets/img/usdt.png"),
        address: "0xdAC17F958D2ee523a2206206994597C13D831ec7",
        decimals: 6,
      },
      {
        Symbol: "USDC",
        name: "USD Coin",
        logo: require("./assets/img/usdc.png"),
        address: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
        decimals: 6,
      },
      {
        Symbol: "DAI",
        name: "Dai Stablecoin",
        logo: require("./assets/img/dai.png"),
        address: "0x6B175474E89094C44Da98b954EedeAC495271d0F",
        decimals: 18,
      },
    ],
    Dexs: [{
        name: "UniSwapV2",
        chain: "Ethereum",
        SwapRouter: "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D",
        logo: require("./assets/img/uni.png"),
      },
      {
        name: "SushiSwap",
        chain: "Ethereum",
        SwapRouter: "0xd9e1cE17f2641f24aE83637ab66a2cca9C378B9F",
        logo: require("./assets/img/su.png"),
      },
    ],
    Common: "0x1453027045D7545260e309A82f48b123c32f5838",
    MultiSend: '0xd85495924f70046723Ab6BE850a22B15dF9b2c53',
  },
  BSC: {
    chain: "BSC",
    ChainId: 56,
    chainName: "Binance Smart Chain",
    gasMulti: 7,
    Symbol: "BNB",
    logo: require("./assets/img/bnbcoin.png"),
    RPC: "https://bsc-dataseed1.binance.org/",
    Browser: "https://bscscan.com/",
    USDT: "0x55d398326f99059fF775485246999027B3197955",
    WETH: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
    Tokens: [{
        Symbol: "BNB",
        name: "BNB",
        logo: require("./assets/img/bsc.png"),
        address: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
        decimals: 18,
      },
      {
        Symbol: "USDT",
        name: "Tether",
        logo: require("./assets/img/usdt.png"),
        address: "0x55d398326f99059fF775485246999027B3197955",
        decimals: 18,
      },
      {
        Symbol: "BUSD",
        name: "Binance USD",
        logo: require("./assets/img/bnbusdt.png"),
        address: "0xe9e7cea3dedca5984780bafc599bd69add087d56",
        decimals: 18,
      },
    ],
    Dexs: [
      {
        name: "PancakeSwap",
        chain: "BSC",
        SwapRouter: "0x10ED43C718714eb63d5aA57B78B54704E256024E",
        logo: require("./assets/img/ex1.png"),
      },
      {
        name: "Sushi",
        chain: "BSC",
        SwapRouter: "0x1b02da8cb0d097eb8d57a175b88c7d8b47997506",
        logo: require("./assets/img/su.png"),
      },
      {
        name: "Biswap",
        chain: "BSC",
        SwapRouter: "0x3a6d8ca21d1cf76f653a67577fa0d27453350dd8",
        logo: require("./assets/img/biswap.png"),
      }
    ],
    Common: "0x4b860FCC0B41283132f4792AD91038DC211831C1",
    MultiSend: '0xBE9f4f99E4FE188428188763EcD53715d713D226',
  },
  "ARB": {
    chain: "ARB",
    ChainId: 42161,
    chainName: "Arbitrum One",
    gasMulti: 26,
    Symbol: "ETH",
    logo: require("./assets/img/arb.png"),
    RPC: 'https://rpc.ankr.com/arbitrum',
    Browser: 'https://arbiscan.io/',
    USDT: '0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9',
    WETH: '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1',
    Tokens: [{
        Symbol: "ETH",
        name: "ETH",
        logo: require("./assets/img/eth_arb.png"),
        address: "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1",
        decimals: 18,
      },
      {
        Symbol: "USDT",
        name: "Tether",
        logo: require("./assets/img/usdt.png"),
        address: "0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9",
        decimals: 18,
      },
      {
        Symbol: "USDC",
        name: "USD Coin",
        logo: require("./assets/img/usdc_arb.png"),
        address: "0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8",
        decimals: 18,
      },
      {
        Symbol: "DAI",
        name: "Dai Stablecoin",
        logo: require("./assets/img/dai.png"),
        address: "0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1",
        decimals: 18,
      },
    ],
    Dexs: [
    //   {
    //   name: "CAMELOT",
    //   chain: "Arbitrum One",
    //   SwapRouter: "0xc873fEcbd354f5A56E00E710B90EF4201db2448d",
    //   logo: require("./assets/img/CAMELOT.png"),
    // },
    {
      name: "Sushi",
      chain: "Arbitrum One",
      SwapRouter: "0x1b02da8cb0d097eb8d57a175b88c7d8b47997506",
      logo: require("./assets/img/su.png"),
    }
    ],
    Common: "0xFE6133e86fb4d5811f2e6881c2E25567B45b60B6",
    MultiSend: '0x548a0260d6329a7aeEc282D95936bD8371358Cf6',
  },
  "OP": {
    chain: "OP",
    ChainId: 10,
    chainName: "Optimism",
    gasMulti: 26,
    Symbol: "ETH",
    logo: require("./assets/img/Optimism.png"),
    RPC: 'https://mainnet.optimism.io',
    Browser: 'https://arbiscan.io/',
    USDT: '0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9',
    WETH: '0x4200000000000000000000000000000000000006',
    Tokens: [{
        Symbol: "ETH",
        name: "ETH",
        logo: require("./assets/img/eth_arb.png"),
        address: "0x4200000000000000000000000000000000000006",
        decimals: 18,
      },
      {
        Symbol: "USDT",
        name: "Tether",
        logo: require("./assets/img/usdt.png"),
        address: "0x94b008aA00579c1307B0EF2c499aD98a8ce58e58",
        decimals: 18,
      },
      {
        Symbol: "USDC",
        name: "USD Coin",
        logo: require("./assets/img/usdc_arb.png"),
        address: "0x7F5c764cBc14f9669B88837ca1490cCa17c31607",
        decimals: 18,
      },
      {
        Symbol: "DAI",
        name: "Dai Stablecoin",
        logo: require("./assets/img/dai.png"),
        address: "0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1",
        decimals: 18,
      },
    ],
    Dexs: [
      {
      name: "Velodrome",
      chain: "Optimism",
      SwapRouter: "0x9c12939390052919aF3155f41Bf4160Fd3666A6f",
      logo: require("./assets/img/Velodrome.png"),
    }
    ],
    Common: "0xFE6133e86fb4d5811f2e6881c2E25567B45b60B6",
    MultiSend: '0x548a0260d6329a7aeEc282D95936bD8371358Cf6',
  },
  "Polygon": {
    chain: "Polygon",
    ChainId: 137,
    chainName: "Polygon",
    gasMulti: 26,
    Symbol: "Matic",
    logo: require("./assets/img/Polygon.png"),
     RPC: 'https://polygon.llamarpc.com',
    Browser: 'https://arbiscan.io/',
    USDT: '0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9',
    WETH: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
    Tokens: [{
        Symbol: "Matic",
        name: "Matic",
        logo: require("./assets/img/Polygon.png"),
        address: "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
        decimals: 18,
      },
      {
        Symbol: "USDT",
        name: "Tether",
        logo: require("./assets/img/usdt.png"),
        address: "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
        decimals: 18,
      },
      {
        Symbol: "USDC",
        name: "USD Coin",
        logo: require("./assets/img/usdc_arb.png"),
        address: "0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174",
        decimals: 18,
      },
      {
        Symbol: "DAI",
        name: "Dai Stablecoin",
        logo: require("./assets/img/dai.png"),
        address: "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
        decimals: 18,
      },
    ],
    Dexs: [
      {
      name: "QuickSwap",
      chain: "Polygon",
      SwapRouter: "0xa5e0829caced8ffdd4de3c43696c57f7d7a678ff",
      logo: require("./assets/img/QuickSwap.png"),
    },
    {
      name: "Sushi",
      chain: "Polygon",
      SwapRouter: "0x1b02dA8Cb0d097eB8D57A175b88c7D8b47997506",
      logo: require("./assets/img/su.png"),
    }
    ],
    Common: "0xC1c5e241dC5F648AB0F66F50121E5aA8548b4fcd",
    MultiSend: '0x52869Bfd715bE279c0312057B1C33e902A3044F0',
  },
  "Fantom": {
    chain: "Fantom",
    ChainId: 250,
    chainName: "Fantom Opera",
    gasMulti: 26,
    Symbol: "FTM",
    logo: require("./assets/img/Fantom.png"),
    RPC: 'https://rpc.ankr.com/fantom',
    Browser: 'https://ftmscan.com/',
    USDC: '0x04068DA6C83AFCFA0e13ba15A6696662335D5B75',
    WETH: '0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83',
    Tokens: [{
        Symbol: "FTM",
        name: "Fantom",
        logo: require("./assets/img/Fantom.png"),
        address: "0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83",
        decimals: 18,
      },
      {
        Symbol: "USDC",
        name: "USD Coin",
        logo: require("./assets/img/usdc_arb.png"),
        address: "0x04068DA6C83AFCFA0e13ba15A6696662335D5B75",
        decimals: 18,
      },
      {
        Symbol: "DAI",
        name: "Dai Stablecoin",
        logo: require("./assets/img/dai.png"),
        address: "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
        decimals: 18,
      },
    ],
    Dexs: [
      {
      name: "SpookySwap",
      chain: "Fantom",
      SwapRouter: "0x31F63A33141fFee63D4B26755430a390ACdD8a4d",
      logo: require("./assets/img/SpookySwap.png"),
    }
    ],
    Common: "0x548a0260d6329a7aeEc282D95936bD8371358Cf6",
    MultiSend: '0xC1c5e241dC5F648AB0F66F50121E5aA8548b4fcd',
  },
  "OKC": {
    chain: "OKC",
    ChainId: 66,
    chainName: "OKX Chain",
    gasMulti: 1,
    Symbol: "OKT",
    logo: require("./assets/img/OKchain.png"),
    RPC: 'https://exchainrpc.okex.org',
    Browser: 'https://www.oklink.com/cn/okc',
    USDT: '0x382bb369d343125bfb2117af9c149795c6c65c50',
    WETH: '0x8f8526dbfd6e38e3d8307702ca8469bae6c56c15',
    Tokens: [{
        Symbol: "OKT",
        name: "OKT Token",
        logo: require("./assets/img/OKchain.png"),
        address: "0x8f8526dbfd6e38e3d8307702ca8469bae6c56c15",
        decimals: 18,
      },
      {
        Symbol: "USDT",
        name: "Tether",
        logo: require("./assets/img/usdt.png"),
        address: "0x382bb369d343125bfb2117af9c149795c6c65c5",
        decimals: 18,
      },
      {
        Symbol: "USDC",
        name: "USD Coin",
        logo: require("./assets/img/usdc_arb.png"),
        address: "0xc946daf81b08146b1c7a8da2a851ddf2b3eaaf85",
        decimals: 18,
      },
      
    ],
    Dexs: [
      {
      name: "OKCSwap",
      chain: "OKX Chain",
      SwapRouter: "0xc97b81B8a38b9146010Df85f1Ac714aFE1554343",
      logo: require("./assets/img/OKchain.png"),
    }
    ],
    Common: "0xCd55940bD435ca24A23e141c096f87aC1d4689A0",
    MultiSend: '0x84E0D301DCEE290f5951376A5b98f19DbD3aC570',
  },
  "CORE": {
    chain: "CORE",
    ChainId: 116,
    chainName: "Core Dao",
    gasMulti: 2,
    Symbol: "CORE",
    logo: require("./assets/img/CORE.png"),
    RPC: 'https://rpc.coredao.org/',
    Browser: 'https://scan.coredao.org/',
    USDT: '0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9',
    WETH: '0x000000000e1d682cc39abe9b32285fdea1255374',
    Tokens: [{
        Symbol: "CORE",
        name: "Core Dao",
        logo: require("./assets/img/CORE.png"),
        address: "0x000000000e1d682cc39abe9b32285fdea1255374",
        decimals: 18,
      },
      {
        Symbol: "USDT",
        name: "Tether",
        logo: require("./assets/img/usdt.png"),
        address: "0x9ebab27608bd64aff36f027049aecc69102a0d1e",
        decimals: 6,
      },
      {
        Symbol: "USDC",
        name: "USD Coin",
        logo: require("./assets/img/usdc_arb.png"),
        address: "0x7F5c764cBc14f9669B88837ca1490cCa17c31607",
        decimals: 18,
      },
      
    ],
    Dexs: [
      {
      name: "LFGSwap",
      chain: "Core Dao",
      SwapRouter: "0x42a0F91973536f85B06B310fa9C70215784F35a1",
      logo: require("./assets/img/lfgswap.png"),
    },
    {
      name: "ShadowSwap",
      chain: "Core Dao",
      SwapRouter: "0xCCED48E6fe655E5F28e8C4e56514276ba8b34C09",
      logo: require("./assets/img/ShadowSwap.png"),
    }
    ],
    Common: "0xFE6133e86fb4d5811f2e6881c2E25567B45b60B6",
    MultiSend: '0x548a0260d6329a7aeEc282D95936bD8371358Cf6',
  },
  //ETHW链配置信息
  EthereumPoW: {
    chain: 'EthereumPoW',
    ChainId: 10001,
    chainName: "ETHW",
    gasMulti: 11,
    Symbol: 'ETHW',
    logo: require("./assets/img/ethw.png"),
    RPC: 'https://mainnet.ethereumpow.org/',
    Browser: 'https://www.oklink.com/en/ethw/',
    USDT: '0x2AD7868CA212135C6119FD7AD1Ce51CFc5702892',
    WETH: '0x7Bf88d2c0e32dE92CdaF2D43CcDc23e8Edfd5990',
    Tokens: [{
      Symbol: 'ETHW',
      name: "EthereumPoW",
      address: "0x7Bf88d2c0e32dE92CdaF2D43CcDc23e8Edfd5990",
      logo: require("./assets/img/ethw.png"),
      decimals: 18,
    }, {
      Symbol: 'USDT',
      name: "Tether",
      address: "0x2AD7868CA212135C6119FD7AD1Ce51CFc5702892",
      logo: require("./assets/img/usdt.png"),
      decimals: 6,
    }],
    Dexs: [{
      name: 'LFGSwap',
      chain: "ETHW",
      SwapRouter: '0x4f381d5fF61ad1D0eC355fEd2Ac4000eA1e67854',
      logo: require("./assets/img/lfgswap.png"),
    }],
    Common: '0x1453027045D7545260e309A82f48b123c32f5838',
    MultiSend: '0x59886a35796e33890dEc08D3468F6C8DCfEa4ea9',
  },
  FON: {
    chain: 'FON',
    ChainId: 201022,
    chainName: "FON",
    gasMulti: 7,
    Symbol: 'FON',
    logo: require("./assets/img/fon.png"),
    RPC: 'https://fsc-dataseed2.fonscan.io/',
    Browser: 'https://fonscan.io/',
    USDT: '0x9a9eD7440a3850c4D7240c9FcA8B7C96802615f0',
    WETH: '0xb582fD9d0D5C3515EEB6b02fF2d6eE0b6E45E7A7',
    Tokens: [{
      Symbol: 'FON',
      name: "Fonvity Chain",
      address: "0xb582fD9d0D5C3515EEB6b02fF2d6eE0b6E45E7A7",
      logo: require("./assets/img/fon.png"),
      decimals: 18,
    }, {
      Symbol: 'WFON',
      name: "Fonvity Chain",
      address: "0xb582fD9d0D5C3515EEB6b02fF2d6eE0b6E45E7A7",
      logo: require("./assets/img/fon.png"),
      decimals: 18,
    }, {
      Symbol: 'USDT',
      name: "Tether",
      address: "0x9a9eD7440a3850c4D7240c9FcA8B7C96802615f0",
      logo: require("./assets/img/usdt.png"),
      decimals: 6,
    }],
    Dexs: [{
      name: 'rosswap',
      chain: "FON",
      SwapRouter: '0x5Df615972954257133d7A0d5fFD68CddD31033d2',
      logo: require("./assets/img/rosswap.png"),
    }],
    Common: '0x1453027045D7545260e309A82f48b123c32f5838',
    MultiSend: '0x4b860FCC0B41283132f4792AD91038DC211831C1',
  },
  Heco: {
    chain: "Heco",
    ChainId: 128,
    chainName: "Huobi ECO Chain", //"Huobi ECO Chain Mainnet",
    gasMulti: 3,
    Symbol: "HT",
    logo: require("./assets/img/ht.png"),
    RPC: "https://http-mainnet.hecochain.com/",
    Browser: "https://www.hecoinfo.com/en-us/",
    USDT: "0xa71EdC38d189767582C38A3145b5873052c3e47a",
    WETH: "0x5545153CCFcA01fbd7Dd11C0b23ba694D9509A6F",
    Tokens: [{
        Symbol: "HT",
        name: "Huobi Token",
        logo: require("./assets/img/ht.png"),
        address: "0x5545153CCFcA01fbd7Dd11C0b23ba694D9509A6F",
        decimals: 18,
      },
      {
        Symbol: "USDT",
        name: "Tether",
        logo: require("./assets/img/usdt.png"),
        address: "0xa71EdC38d189767582C38A3145b5873052c3e47a",
        decimals: 18,
      },
      // {
      //   Symbol: "HUSD",
      //   name: "HUSD",
      //   logo: require("./assets/img/husd.png"),
      //   address: "0x0298c2b32eaE4da002a15f36fdf7615BEa3DA047",
      //   decimals: 8,
      // },
    ],
    Dexs: [{
        name: "MDEX",
        chain: "Heco",
        SwapRouter: "0x0f1c2D1FDD202768A4bDa7A38EB0377BD58d278E",
        logo: require("./assets/img/ex3.png"),
      },
      {
        name: "PIPPI",
        chain: "Heco",
        SwapRouter: "0xBe4AB2603140F134869cb32aB4BC56d762Ae900B",
        logo: require("./assets/img/ex2.png"),
      },
    ],
    Common: "0x5e43d6dBdF6CEa7dbdBfF21168f1C8fCcF57161B",
    MultiSend: '0x1453027045D7545260e309A82f48b123c32f5838',
  },
}
export const vipChainId = 56 //heco
export const CheckVipRpc = 'https://bsc-dataseed1.binance.org/' //heco
export const contractAddress = '0x833A0a7333CEb2bbB4927782cDDB14FA34B22535'
export const tokenAddress = '0xfF4F314CB37AE61e22D63B282B5D1c1630F7FED2'
export const lpAddress = '0x8e10cdbD2Dd0E4326708254f244CEa484465C752'
export const ZERO_ADDRESS = "0x0000000000000000000000000000000000000000";
export const MAX_INT = "115792089237316195423570985008687907853269984665640564039457584007913129639935";
export const VipSale = "0x76fFFAEFd0d6B7CEE2ecDeE5976fE0bb4d87AF58"//"0x6a181e2d585984Cd32ed9E12cdF9ae14E710c140"
export const DxSaleInfoAddress = "0x7100c01f668a5b407db6a77821ddb035561f25b8"
export const MultiSend_ABI = [{
    "inputs": [],
    "stateMutability": "nonpayable",
    "type": "constructor"
  },
  {
    "inputs": [{
      "internalType": "uint256",
      "name": "amount",
      "type": "uint256"
    }],
    "name": "claimETH",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [{
        "internalType": "address",
        "name": "token",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "amount",
        "type": "uint256"
      }
    ],
    "name": "claimToken",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "owner",
    "outputs": [{
      "internalType": "address",
      "name": "",
      "type": "address"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [{
        "internalType": "address[]",
        "name": "tos",
        "type": "address[]"
      },
      {
        "internalType": "uint256",
        "name": "perAmount",
        "type": "uint256"
      }
    ],
    "name": "sendETH",
    "outputs": [],
    "stateMutability": "payable",
    "type": "function"
  },
  {
    "inputs": [{
        "internalType": "address[]",
        "name": "tos",
        "type": "address[]"
      },
      {
        "internalType": "uint256[]",
        "name": "amounts",
        "type": "uint256[]"
      }
    ],
    "name": "sendETHs",
    "outputs": [],
    "stateMutability": "payable",
    "type": "function"
  },
  {
    "inputs": [{
        "internalType": "address",
        "name": "token",
        "type": "address"
      },
      {
        "internalType": "address[]",
        "name": "tos",
        "type": "address[]"
      },
      {
        "internalType": "uint256",
        "name": "perAmount",
        "type": "uint256"
      }
    ],
    "name": "sendToken",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [{
        "internalType": "address",
        "name": "token",
        "type": "address"
      },
      {
        "internalType": "address[]",
        "name": "tos",
        "type": "address[]"
      },
      {
        "internalType": "uint256",
        "name": "perAmount",
        "type": "uint256"
      }
    ],
    "name": "sendTokenV2",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [{
        "internalType": "address",
        "name": "token",
        "type": "address"
      },
      {
        "internalType": "address[]",
        "name": "tos",
        "type": "address[]"
      },
      {
        "internalType": "uint256[]",
        "name": "amounts",
        "type": "uint256[]"
      }
    ],
    "name": "sendTokens",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "stateMutability": "payable",
    "type": "receive"
  }
]
export const SwapRouter_ABI = [{
    "inputs": [{
        "internalType": "address",
        "name": "_factory",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "_WETH",
        "type": "address"
      }
    ],
    "stateMutability": "nonpayable",
    "type": "constructor"
  },
  {
    "inputs": [],
    "name": "WETH",
    "outputs": [{
      "internalType": "address",
      "name": "",
      "type": "address"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [{
        "internalType": "address",
        "name": "tokenA",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "tokenB",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "amountADesired",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "amountBDesired",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "amountAMin",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "amountBMin",
        "type": "uint256"
      },
      {
        "internalType": "address",
        "name": "to",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "deadline",
        "type": "uint256"
      }
    ],
    "name": "addLiquidity",
    "outputs": [{
        "internalType": "uint256",
        "name": "amountA",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "amountB",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "liquidity",
        "type": "uint256"
      }
    ],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [{
        "internalType": "address",
        "name": "token",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "amountTokenDesired",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "amountTokenMin",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "amountETHMin",
        "type": "uint256"
      },
      {
        "internalType": "address",
        "name": "to",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "deadline",
        "type": "uint256"
      }
    ],
    "name": "addLiquidityETH",
    "outputs": [{
        "internalType": "uint256",
        "name": "amountToken",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "amountETH",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "liquidity",
        "type": "uint256"
      }
    ],
    "stateMutability": "payable",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "factory",
    "outputs": [{
      "internalType": "address",
      "name": "",
      "type": "address"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [{
        "internalType": "uint256",
        "name": "amountOut",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "reserveIn",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "reserveOut",
        "type": "uint256"
      }
    ],
    "name": "getAmountIn",
    "outputs": [{
      "internalType": "uint256",
      "name": "amountIn",
      "type": "uint256"
    }],
    "stateMutability": "pure",
    "type": "function"
  },
  {
    "inputs": [{
        "internalType": "uint256",
        "name": "amountIn",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "reserveIn",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "reserveOut",
        "type": "uint256"
      }
    ],
    "name": "getAmountOut",
    "outputs": [{
      "internalType": "uint256",
      "name": "amountOut",
      "type": "uint256"
    }],
    "stateMutability": "pure",
    "type": "function"
  },
  {
    "inputs": [{
        "internalType": "uint256",
        "name": "amountOut",
        "type": "uint256"
      },
      {
        "internalType": "address[]",
        "name": "path",
        "type": "address[]"
      }
    ],
    "name": "getAmountsIn",
    "outputs": [{
      "internalType": "uint256[]",
      "name": "amounts",
      "type": "uint256[]"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [{
        "internalType": "uint256",
        "name": "amountIn",
        "type": "uint256"
      },
      {
        "internalType": "address[]",
        "name": "path",
        "type": "address[]"
      }
    ],
    "name": "getAmountsOut",
    "outputs": [{
      "internalType": "uint256[]",
      "name": "amounts",
      "type": "uint256[]"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [{
        "internalType": "uint256",
        "name": "amountA",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "reserveA",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "reserveB",
        "type": "uint256"
      }
    ],
    "name": "quote",
    "outputs": [{
      "internalType": "uint256",
      "name": "amountB",
      "type": "uint256"
    }],
    "stateMutability": "pure",
    "type": "function"
  },
  {
    "inputs": [{
        "internalType": "address",
        "name": "tokenA",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "tokenB",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "liquidity",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "amountAMin",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "amountBMin",
        "type": "uint256"
      },
      {
        "internalType": "address",
        "name": "to",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "deadline",
        "type": "uint256"
      }
    ],
    "name": "removeLiquidity",
    "outputs": [{
        "internalType": "uint256",
        "name": "amountA",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "amountB",
        "type": "uint256"
      }
    ],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [{
        "internalType": "address",
        "name": "token",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "liquidity",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "amountTokenMin",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "amountETHMin",
        "type": "uint256"
      },
      {
        "internalType": "address",
        "name": "to",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "deadline",
        "type": "uint256"
      }
    ],
    "name": "removeLiquidityETH",
    "outputs": [{
        "internalType": "uint256",
        "name": "amountToken",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "amountETH",
        "type": "uint256"
      }
    ],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [{
        "internalType": "address",
        "name": "token",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "liquidity",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "amountTokenMin",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "amountETHMin",
        "type": "uint256"
      },
      {
        "internalType": "address",
        "name": "to",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "deadline",
        "type": "uint256"
      }
    ],
    "name": "removeLiquidityETHSupportingFeeOnTransferTokens",
    "outputs": [{
      "internalType": "uint256",
      "name": "amountETH",
      "type": "uint256"
    }],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [{
        "internalType": "address",
        "name": "token",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "liquidity",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "amountTokenMin",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "amountETHMin",
        "type": "uint256"
      },
      {
        "internalType": "address",
        "name": "to",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "deadline",
        "type": "uint256"
      },
      {
        "internalType": "bool",
        "name": "approveMax",
        "type": "bool"
      },
      {
        "internalType": "uint8",
        "name": "v",
        "type": "uint8"
      },
      {
        "internalType": "bytes32",
        "name": "r",
        "type": "bytes32"
      },
      {
        "internalType": "bytes32",
        "name": "s",
        "type": "bytes32"
      }
    ],
    "name": "removeLiquidityETHWithPermit",
    "outputs": [{
        "internalType": "uint256",
        "name": "amountToken",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "amountETH",
        "type": "uint256"
      }
    ],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [{
        "internalType": "address",
        "name": "token",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "liquidity",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "amountTokenMin",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "amountETHMin",
        "type": "uint256"
      },
      {
        "internalType": "address",
        "name": "to",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "deadline",
        "type": "uint256"
      },
      {
        "internalType": "bool",
        "name": "approveMax",
        "type": "bool"
      },
      {
        "internalType": "uint8",
        "name": "v",
        "type": "uint8"
      },
      {
        "internalType": "bytes32",
        "name": "r",
        "type": "bytes32"
      },
      {
        "internalType": "bytes32",
        "name": "s",
        "type": "bytes32"
      }
    ],
    "name": "removeLiquidityETHWithPermitSupportingFeeOnTransferTokens",
    "outputs": [{
      "internalType": "uint256",
      "name": "amountETH",
      "type": "uint256"
    }],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [{
        "internalType": "address",
        "name": "tokenA",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "tokenB",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "liquidity",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "amountAMin",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "amountBMin",
        "type": "uint256"
      },
      {
        "internalType": "address",
        "name": "to",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "deadline",
        "type": "uint256"
      },
      {
        "internalType": "bool",
        "name": "approveMax",
        "type": "bool"
      },
      {
        "internalType": "uint8",
        "name": "v",
        "type": "uint8"
      },
      {
        "internalType": "bytes32",
        "name": "r",
        "type": "bytes32"
      },
      {
        "internalType": "bytes32",
        "name": "s",
        "type": "bytes32"
      }
    ],
    "name": "removeLiquidityWithPermit",
    "outputs": [{
        "internalType": "uint256",
        "name": "amountA",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "amountB",
        "type": "uint256"
      }
    ],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [{
        "internalType": "uint256",
        "name": "amountOut",
        "type": "uint256"
      },
      {
        "internalType": "address[]",
        "name": "path",
        "type": "address[]"
      },
      {
        "internalType": "address",
        "name": "to",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "deadline",
        "type": "uint256"
      }
    ],
    "name": "swapETHForExactTokens",
    "outputs": [{
      "internalType": "uint256[]",
      "name": "amounts",
      "type": "uint256[]"
    }],
    "stateMutability": "payable",
    "type": "function"
  },
  {
    "inputs": [{
        "internalType": "uint256",
        "name": "amountOutMin",
        "type": "uint256"
      },
      {
        "internalType": "address[]",
        "name": "path",
        "type": "address[]"
      },
      {
        "internalType": "address",
        "name": "to",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "deadline",
        "type": "uint256"
      }
    ],
    "name": "swapExactETHForTokens",
    "outputs": [{
      "internalType": "uint256[]",
      "name": "amounts",
      "type": "uint256[]"
    }],
    "stateMutability": "payable",
    "type": "function"
  },
  {
    "inputs": [{
        "internalType": "uint256",
        "name": "amountOutMin",
        "type": "uint256"
      },
      {
        "internalType": "address[]",
        "name": "path",
        "type": "address[]"
      },
      {
        "internalType": "address",
        "name": "to",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "deadline",
        "type": "uint256"
      }
    ],
    "name": "swapExactETHForTokensSupportingFeeOnTransferTokens",
    "outputs": [],
    "stateMutability": "payable",
    "type": "function"
  },
  {
    "inputs": [{
        "internalType": "uint256",
        "name": "amountIn",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "amountOutMin",
        "type": "uint256"
      },
      {
        "internalType": "address[]",
        "name": "path",
        "type": "address[]"
      },
      {
        "internalType": "address",
        "name": "to",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "deadline",
        "type": "uint256"
      }
    ],
    "name": "swapExactTokensForETH",
    "outputs": [{
      "internalType": "uint256[]",
      "name": "amounts",
      "type": "uint256[]"
    }],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [{
        "internalType": "uint256",
        "name": "amountIn",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "amountOutMin",
        "type": "uint256"
      },
      {
        "internalType": "address[]",
        "name": "path",
        "type": "address[]"
      },
      {
        "internalType": "address",
        "name": "to",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "deadline",
        "type": "uint256"
      }
    ],
    "name": "swapExactTokensForETHSupportingFeeOnTransferTokens",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [{
        "internalType": "uint256",
        "name": "amountIn",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "amountOutMin",
        "type": "uint256"
      },
      {
        "internalType": "address[]",
        "name": "path",
        "type": "address[]"
      },
      {
        "internalType": "address",
        "name": "to",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "deadline",
        "type": "uint256"
      }
    ],
    "name": "swapExactTokensForTokens",
    "outputs": [{
      "internalType": "uint256[]",
      "name": "amounts",
      "type": "uint256[]"
    }],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [{
        "internalType": "uint256",
        "name": "amountIn",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "amountOutMin",
        "type": "uint256"
      },
      {
        "internalType": "address[]",
        "name": "path",
        "type": "address[]"
      },
      {
        "internalType": "address",
        "name": "to",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "deadline",
        "type": "uint256"
      }
    ],
    "name": "swapExactTokensForTokensSupportingFeeOnTransferTokens",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [{
        "internalType": "uint256",
        "name": "amountOut",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "amountInMax",
        "type": "uint256"
      },
      {
        "internalType": "address[]",
        "name": "path",
        "type": "address[]"
      },
      {
        "internalType": "address",
        "name": "to",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "deadline",
        "type": "uint256"
      }
    ],
    "name": "swapTokensForExactETH",
    "outputs": [{
      "internalType": "uint256[]",
      "name": "amounts",
      "type": "uint256[]"
    }],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [{
        "internalType": "uint256",
        "name": "amountOut",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "amountInMax",
        "type": "uint256"
      },
      {
        "internalType": "address[]",
        "name": "path",
        "type": "address[]"
      },
      {
        "internalType": "address",
        "name": "to",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "deadline",
        "type": "uint256"
      }
    ],
    "name": "swapTokensForExactTokens",
    "outputs": [{
      "internalType": "uint256[]",
      "name": "amounts",
      "type": "uint256[]"
    }],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "stateMutability": "payable",
    "type": "receive"
  }
]
export const Common_ABI = [{
    "inputs": [],
    "stateMutability": "nonpayable",
    "type": "constructor"
  },
  {
    "inputs": [{
        "internalType": "address",
        "name": "tokenAddress",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "tokenReverse",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "usdtReverse",
        "type": "uint256"
      }
    ],
    "name": "_calTokenPrice",
    "outputs": [{
        "internalType": "uint256",
        "name": "tokenPrice",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "priceDecimals",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [{
        "internalType": "address",
        "name": "swapRouter",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "tokenAddress",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "usdtAddress",
        "type": "address"
      }
    ],
    "name": "_getTokenPrice",
    "outputs": [{
        "internalType": "uint256",
        "name": "tokenPrice",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "priceDecimals",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "tokenReserve",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [{
      "internalType": "uint256",
      "name": "amount",
      "type": "uint256"
    }],
    "name": "claimETH",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [{
        "internalType": "address",
        "name": "token",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "amount",
        "type": "uint256"
      }
    ],
    "name": "claimToken",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [{
        "internalType": "address",
        "name": "swapRouter",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "tokenA",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "tokenB",
        "type": "address"
      }
    ],
    "name": "getReserves",
    "outputs": [{
        "internalType": "uint256",
        "name": "reverseA",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "reverseB",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [{
      "internalType": "address",
      "name": "tokenAddress",
      "type": "address"
    }],
    "name": "getTokenInfo",
    "outputs": [{
        "internalType": "string",
        "name": "symbol",
        "type": "string"
      },
      {
        "internalType": "uint256",
        "name": "decimals",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "totalSupply",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [{
      "internalType": "address[]",
      "name": "tokenAddress",
      "type": "address[]"
    }],
    "name": "getTokenInfos",
    "outputs": [{
        "internalType": "string[]",
        "name": "symbol",
        "type": "string[]"
      },
      {
        "internalType": "uint256[]",
        "name": "decimals",
        "type": "uint256[]"
      },
      {
        "internalType": "uint256[]",
        "name": "totalSupply",
        "type": "uint256[]"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [{
        "internalType": "address",
        "name": "swapRouter",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "tokenAddress",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "usdtAddress",
        "type": "address"
      },
      {
        "internalType": "address[]",
        "name": "others",
        "type": "address[]"
      }
    ],
    "name": "getTokenPrice",
    "outputs": [{
        "internalType": "uint256",
        "name": "tokenPrice",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "priceDecimals",
        "type": "uint256"
      },
      {
        "internalType": "address",
        "name": "pairOther",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "maxTokenReserve",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [{
        "internalType": "uint256",
        "name": "otherReverse",
        "type": "uint256"
      },
      {
        "internalType": "address",
        "name": "swapRouter",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "otherTokenAddress",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "usdtAddress",
        "type": "address"
      }
    ],
    "name": "getUsdtReverse",
    "outputs": [{
      "internalType": "uint256",
      "name": "usdtReserve",
      "type": "uint256"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "owner",
    "outputs": [{
      "internalType": "address",
      "name": "",
      "type": "address"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "stateMutability": "payable",
    "type": "receive"
  }
]
export const VipSale_ABI = [
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "price",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "duration",
				"type": "uint256"
			}
		],
		"name": "addSale",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "account",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "time",
				"type": "uint256"
			}
		],
		"name": "addUserVipTime",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "saleId",
				"type": "uint256"
			},
			{
				"internalType": "address",
				"name": "invitor",
				"type": "address"
			}
		],
		"name": "buy",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			}
		],
		"name": "claimBalance",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "erc20Address",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "claimToken",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "saleId",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "duration",
				"type": "uint256"
			}
		],
		"name": "setDuration",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"stateMutability": "nonpayable",
		"type": "constructor"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "previousOwner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "OwnershipTransferred",
		"type": "event"
	},
	{
		"inputs": [],
		"name": "renounceOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "adr",
				"type": "address"
			}
		],
		"name": "setCashAddress",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "i",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "fee",
				"type": "uint256"
			}
		],
		"name": "setInviteFee",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "len",
				"type": "uint256"
			}
		],
		"name": "setInviteLength",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "saleId",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "price",
				"type": "uint256"
			}
		],
		"name": "setPrice",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "Symbol",
				"type": "string"
			}
		],
		"name": "setPriceSymbol",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "i",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "fee",
				"type": "uint256"
			}
		],
		"name": "setSuperVipInviteFee",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "account",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "time",
				"type": "uint256"
			}
		],
		"name": "setUserVipTime",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address[]",
				"name": "accounts",
				"type": "address[]"
			},
			{
				"internalType": "uint256[]",
				"name": "times",
				"type": "uint256[]"
			}
		],
		"name": "setUserVipTimes",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "transferOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"stateMutability": "payable",
		"type": "receive"
	},
	{
		"inputs": [],
		"name": "_cashAddress",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "_inviteFee",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "_invitor",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "_maxVipNum",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "_superVipInviteFee",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "_superVipInviteLength",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "allSales",
		"outputs": [
			{
				"internalType": "uint256[]",
				"name": "price",
				"type": "uint256[]"
			},
			{
				"internalType": "uint256[]",
				"name": "duration",
				"type": "uint256[]"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "sid",
				"type": "uint256"
			}
		],
		"name": "getSaleInfo",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "price",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "duration",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "getSaleLength",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "account",
				"type": "address"
			}
		],
		"name": "getUserInfo",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "endTime",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "balance",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "blockTime",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "owner",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "shopInfo",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "priceDecimals",
				"type": "uint256"
			},
			{
				"internalType": "string",
				"name": "priceSymbol",
				"type": "string"
			},
			{
				"internalType": "uint256",
				"name": "timestamp",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "totalAmount",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "totalInviteAmount",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "maxVipNum",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	}
]
export const ERC20_ABI = [{
    "inputs": [{
        "internalType": "address",
        "name": "owner",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "spender",
        "type": "address"
      }
    ],
    "name": "allowance",
    "outputs": [{
      "internalType": "uint256",
      "name": "",
      "type": "uint256"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [{
        "internalType": "address",
        "name": "spender",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "amount",
        "type": "uint256"
      }
    ],
    "name": "approve",
    "outputs": [{
      "internalType": "bool",
      "name": "",
      "type": "bool"
    }],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [{
      "internalType": "address",
      "name": "account",
      "type": "address"
    }],
    "name": "balanceOf",
    "outputs": [{
      "internalType": "uint256",
      "name": "",
      "type": "uint256"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "decimals",
    "outputs": [{
      "internalType": "uint8",
      "name": "",
      "type": "uint8"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "name",
    "outputs": [{
      "internalType": "string",
      "name": "",
      "type": "string"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "symbol",
    "outputs": [{
      "internalType": "string",
      "name": "",
      "type": "string"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "totalSupply",
    "outputs": [{
      "internalType": "uint256",
      "name": "",
      "type": "uint256"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [{
        "internalType": "address",
        "name": "recipient",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "amount",
        "type": "uint256"
      }
    ],
    "name": "transfer",
    "outputs": [{
      "internalType": "bool",
      "name": "",
      "type": "bool"
    }],
    "stateMutability": "nonpayable",
    "type": "function"
  }
]
export const tokenABI = [{
    "inputs": [{
        "internalType": "address",
        "name": "owner",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "spender",
        "type": "address"
      }
    ],
    "name": "allowance",
    "outputs": [{
      "internalType": "uint256",
      "name": "",
      "type": "uint256"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [{
        "internalType": "address",
        "name": "spender",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "amount",
        "type": "uint256"
      }
    ],
    "name": "approve",
    "outputs": [{
      "internalType": "bool",
      "name": "",
      "type": "bool"
    }],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [{
      "internalType": "address",
      "name": "account",
      "type": "address"
    }],
    "name": "balanceOf",
    "outputs": [{
      "internalType": "uint256",
      "name": "",
      "type": "uint256"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "decimals",
    "outputs": [{
      "internalType": "uint8",
      "name": "",
      "type": "uint8"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "name",
    "outputs": [{
      "internalType": "string",
      "name": "",
      "type": "string"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "symbol",
    "outputs": [{
      "internalType": "string",
      "name": "",
      "type": "string"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "totalSupply",
    "outputs": [{
      "internalType": "uint256",
      "name": "",
      "type": "uint256"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [{
        "internalType": "address",
        "name": "recipient",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "amount",
        "type": "uint256"
      }
    ],
    "name": "transfer",
    "outputs": [{
      "internalType": "bool",
      "name": "",
      "type": "bool"
    }],
    "stateMutability": "nonpayable",
    "type": "function"
  }
]
export const contractABI = [{
    "inputs": [],
    "stateMutability": "nonpayable",
    "type": "constructor"
  },
  {
    "anonymous": false,
    "inputs": [{
        "indexed": true,
        "internalType": "address",
        "name": "previousOwner",
        "type": "address"
      },
      {
        "indexed": true,
        "internalType": "address",
        "name": "newOwner",
        "type": "address"
      }
    ],
    "name": "OwnershipTransferred",
    "type": "event"
  },
  {
    "inputs": [],
    "name": "_cashAddress",
    "outputs": [{
      "internalType": "address",
      "name": "",
      "type": "address"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [{
      "internalType": "uint256",
      "name": "saleId",
      "type": "uint256"
    }],
    "name": "buy",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "claim",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [{
      "internalType": "address",
      "name": "to",
      "type": "address"
    }],
    "name": "claimBalance",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [{
        "internalType": "address",
        "name": "erc20Address",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "to",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "amount",
        "type": "uint256"
      }
    ],
    "name": "claimToken",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [{
      "internalType": "uint256",
      "name": "sid",
      "type": "uint256"
    }],
    "name": "getSaleInfo",
    "outputs": [{
        "internalType": "uint256",
        "name": "price",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "tokenNum",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "qty",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "saleNum",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "getSaleLength",
    "outputs": [{
      "internalType": "uint256",
      "name": "",
      "type": "uint256"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [{
      "internalType": "address",
      "name": "account",
      "type": "address"
    }],
    "name": "getUserInfo",
    "outputs": [{
        "internalType": "uint256",
        "name": "buyNum",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "buyAmount",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "buyTokenAmount",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "claimedTokenAmount",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "balance",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "allowance",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "owner",
    "outputs": [{
      "internalType": "address",
      "name": "",
      "type": "address"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "renounceOwnership",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [{
      "internalType": "uint256",
      "name": "endTime",
      "type": "uint256"
    }],
    "name": "setEndTime",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [{
      "internalType": "uint256",
      "name": "max",
      "type": "uint256"
    }],
    "name": "setMaxBuyNum",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [{
      "internalType": "bool",
      "name": "pause",
      "type": "bool"
    }],
    "name": "setPauseBuy",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [{
      "internalType": "bool",
      "name": "pause",
      "type": "bool"
    }],
    "name": "setPauseClaim",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [{
        "internalType": "uint256",
        "name": "saleId",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "price",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "tokenNum",
        "type": "uint256"
      }
    ],
    "name": "setPrice",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [{
        "internalType": "uint256",
        "name": "saleId",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "qty",
        "type": "uint256"
      }
    ],
    "name": "setQty",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [{
      "internalType": "address",
      "name": "adr",
      "type": "address"
    }],
    "name": "setTokenAddress",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [{
      "internalType": "address",
      "name": "adr",
      "type": "address"
    }],
    "name": "setUsdtAddress",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "shopInfo",
    "outputs": [{
        "internalType": "address",
        "name": "usdtAddress",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "usdtDecimals",
        "type": "uint256"
      },
      {
        "internalType": "string",
        "name": "usdtSymbol",
        "type": "string"
      },
      {
        "internalType": "address",
        "name": "tokenAddress",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "tokenDecimals",
        "type": "uint256"
      },
      {
        "internalType": "string",
        "name": "tokenSymbol",
        "type": "string"
      },
      {
        "internalType": "uint256",
        "name": "maxBuyNum",
        "type": "uint256"
      },
      {
        "internalType": "bool",
        "name": "pauseBuy",
        "type": "bool"
      },
      {
        "internalType": "bool",
        "name": "pauseClaim",
        "type": "bool"
      },
      {
        "internalType": "uint256",
        "name": "endTime",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "timestamp",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "totalUsdt",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "totalToken",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [{
      "internalType": "address",
      "name": "newOwner",
      "type": "address"
    }],
    "name": "transferOwnership",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "stateMutability": "payable",
    "type": "receive"
  }
]
export const lpABI = [{
    "inputs": [],
    "stateMutability": "nonpayable",
    "type": "constructor"
  },
  {
    "anonymous": false,
    "inputs": [{
        "indexed": true,
        "internalType": "address",
        "name": "previousOwner",
        "type": "address"
      },
      {
        "indexed": true,
        "internalType": "address",
        "name": "newOwner",
        "type": "address"
      }
    ],
    "name": "OwnershipTransferred",
    "type": "event"
  },
  {
    "inputs": [],
    "name": "_accDividendReward",
    "outputs": [{
      "internalType": "uint256",
      "name": "",
      "type": "uint256"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "_accDividendRewardPerShare",
    "outputs": [{
      "internalType": "uint256",
      "name": "",
      "type": "uint256"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [{
        "internalType": "address",
        "name": "",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "name": "_binder",
    "outputs": [{
      "internalType": "address",
      "name": "",
      "type": "address"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [{
      "internalType": "address",
      "name": "",
      "type": "address"
    }],
    "name": "_dividendRewardDebt",
    "outputs": [{
      "internalType": "uint256",
      "name": "",
      "type": "uint256"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "_dividendRewardToken",
    "outputs": [{
      "internalType": "address",
      "name": "",
      "type": "address"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "_factory",
    "outputs": [{
      "internalType": "contract ISwapFactory",
      "name": "",
      "type": "address"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "_feeDivFactor",
    "outputs": [{
      "internalType": "uint256",
      "name": "",
      "type": "uint256"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "_inviteFee",
    "outputs": [{
      "internalType": "uint256",
      "name": "",
      "type": "uint256"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [{
      "internalType": "address",
      "name": "",
      "type": "address"
    }],
    "name": "_invitor",
    "outputs": [{
      "internalType": "address",
      "name": "",
      "type": "address"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [{
      "internalType": "address",
      "name": "",
      "type": "address"
    }],
    "name": "_poolAdmin",
    "outputs": [{
      "internalType": "bool",
      "name": "",
      "type": "bool"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [{
      "internalType": "address",
      "name": "",
      "type": "address"
    }],
    "name": "_poolLpBalances",
    "outputs": [{
      "internalType": "uint256",
      "name": "",
      "type": "uint256"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "_rewardFactor",
    "outputs": [{
      "internalType": "uint256",
      "name": "",
      "type": "uint256"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [{
      "internalType": "address",
      "name": "",
      "type": "address"
    }],
    "name": "_singleToken",
    "outputs": [{
      "internalType": "bool",
      "name": "",
      "type": "bool"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "_swapRouter",
    "outputs": [{
      "internalType": "contract ISwapRouter",
      "name": "",
      "type": "address"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [{
        "internalType": "address",
        "name": "lpToken",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "rewardToken",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "rewardPerBlock",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "startTime",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "endTime",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "timePerBlock",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "totalReward",
        "type": "uint256"
      }
    ],
    "name": "addPool",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [{
      "internalType": "uint256",
      "name": "reward",
      "type": "uint256"
    }],
    "name": "addTokenReward",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [{
      "internalType": "address",
      "name": "invitor",
      "type": "address"
    }],
    "name": "bindInvitor",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [{
      "internalType": "uint256",
      "name": "pid",
      "type": "uint256"
    }],
    "name": "claim",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [{
        "internalType": "address",
        "name": "to",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "amount",
        "type": "uint256"
      }
    ],
    "name": "claimBalance",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [{
        "internalType": "address",
        "name": "token",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "to",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "amount",
        "type": "uint256"
      }
    ],
    "name": "claimToken",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [{
        "internalType": "uint256",
        "name": "pid",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "amount",
        "type": "uint256"
      }
    ],
    "name": "deposit",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [{
      "internalType": "uint256",
      "name": "pid",
      "type": "uint256"
    }],
    "name": "emergencyWithdraw",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "getBaseInfo",
    "outputs": [{
        "internalType": "address",
        "name": "usdtAddress",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "usdtDecimals",
        "type": "uint256"
      },
      {
        "internalType": "string",
        "name": "usdtSymbol",
        "type": "string"
      },
      {
        "internalType": "uint256",
        "name": "timestamp",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "blockNum",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [{
      "internalType": "address",
      "name": "account",
      "type": "address"
    }],
    "name": "getBinderLength",
    "outputs": [{
      "internalType": "uint256",
      "name": "",
      "type": "uint256"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [{
      "internalType": "uint256",
      "name": "pid",
      "type": "uint256"
    }],
    "name": "getPoolData",
    "outputs": [{
        "internalType": "uint256",
        "name": "reward",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "totalReward",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "startTime",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "endTime",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [{
      "internalType": "uint256",
      "name": "pid",
      "type": "uint256"
    }],
    "name": "getPoolExtInfo",
    "outputs": [{
        "internalType": "uint256",
        "name": "rewardTokenDecimals",
        "type": "uint256"
      },
      {
        "internalType": "string",
        "name": "rewardTokenSymbol",
        "type": "string"
      },
      {
        "internalType": "uint256",
        "name": "lpTokenDecimals",
        "type": "uint256"
      },
      {
        "internalType": "string",
        "name": "lpToken0Symbol",
        "type": "string"
      },
      {
        "internalType": "string",
        "name": "lpToken1Symbol",
        "type": "string"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [{
      "internalType": "uint256",
      "name": "pid",
      "type": "uint256"
    }],
    "name": "getPoolInfo",
    "outputs": [{
        "internalType": "address",
        "name": "lpToken",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "rewardToken",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "rewardPerBlock",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "amount",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "lpPrice",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "rewardTokenPrice",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [{
      "internalType": "address",
      "name": "token",
      "type": "address"
    }],
    "name": "getTokenPrice",
    "outputs": [{
      "internalType": "uint256",
      "name": "price",
      "type": "uint256"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [{
        "internalType": "uint256",
        "name": "pid",
        "type": "uint256"
      },
      {
        "internalType": "address",
        "name": "account",
        "type": "address"
      }
    ],
    "name": "getUserInfo",
    "outputs": [{
        "internalType": "uint256",
        "name": "amount",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "pending",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "lpBalance",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "lpAllowance",
        "type": "uint256"
      },
      {
        "internalType": "bool",
        "name": "active",
        "type": "bool"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "owner",
    "outputs": [{
      "internalType": "address",
      "name": "",
      "type": "address"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [{
      "internalType": "address",
      "name": "account",
      "type": "address"
    }],
    "name": "pendingDividend",
    "outputs": [{
        "internalType": "uint256",
        "name": "decimals",
        "type": "uint256"
      },
      {
        "internalType": "string",
        "name": "symbol",
        "type": "string"
      },
      {
        "internalType": "uint256",
        "name": "reward",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "poolLength",
    "outputs": [{
      "internalType": "uint256",
      "name": "",
      "type": "uint256"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "renounceOwnership",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [{
      "internalType": "uint256",
      "name": "fee",
      "type": "uint256"
    }],
    "name": "setInviteFee",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [{
        "internalType": "address",
        "name": "adr",
        "type": "address"
      },
      {
        "internalType": "bool",
        "name": "enable",
        "type": "bool"
      }
    ],
    "name": "setPoolAdmin",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [{
        "internalType": "uint256",
        "name": "pid",
        "type": "uint256"
      },
      {
        "internalType": "address",
        "name": "lp",
        "type": "address"
      }
    ],
    "name": "setPoolLP",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [{
        "internalType": "uint256",
        "name": "pid",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "rewardPerBlock",
        "type": "uint256"
      }
    ],
    "name": "setPoolRewardPerBlock",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [{
        "internalType": "uint256",
        "name": "pid",
        "type": "uint256"
      },
      {
        "internalType": "address",
        "name": "token",
        "type": "address"
      }
    ],
    "name": "setPoolRewardToken",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [{
        "internalType": "uint256",
        "name": "pid",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "startTime",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "endTime",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "timePerBlock",
        "type": "uint256"
      }
    ],
    "name": "setPoolTime",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [{
        "internalType": "uint256",
        "name": "pid",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "totalReward",
        "type": "uint256"
      }
    ],
    "name": "setPoolTotalReward",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [{
        "internalType": "address",
        "name": "token",
        "type": "address"
      },
      {
        "internalType": "bool",
        "name": "enable",
        "type": "bool"
      }
    ],
    "name": "setSingleToken",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [{
      "internalType": "address",
      "name": "swapRouterAddress",
      "type": "address"
    }],
    "name": "setSwapRouter",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [{
      "internalType": "address",
      "name": "usdt",
      "type": "address"
    }],
    "name": "setUsdtAddress",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [{
      "internalType": "uint256",
      "name": "pid",
      "type": "uint256"
    }],
    "name": "startPool",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [{
      "internalType": "address",
      "name": "newOwner",
      "type": "address"
    }],
    "name": "transferOwnership",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [{
        "internalType": "uint256",
        "name": "pid",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "amount",
        "type": "uint256"
      }
    ],
    "name": "withdraw",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "stateMutability": "payable",
    "type": "receive"
  }
]
export const PinkSale_ABI = [{
    "anonymous": false,
    "inputs": [{
      "indexed": false,
      "internalType": "uint256",
      "name": "timestamp",
      "type": "uint256"
    }],
    "name": "Cancelled",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [{
        "indexed": true,
        "internalType": "address",
        "name": "user",
        "type": "address"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "volume",
        "type": "uint256"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "total",
        "type": "uint256"
      }
    ],
    "name": "Claimed",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [{
        "indexed": true,
        "internalType": "address",
        "name": "user",
        "type": "address"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "amount",
        "type": "uint256"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "volume",
        "type": "uint256"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "total",
        "type": "uint256"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "timestamp",
        "type": "uint256"
      }
    ],
    "name": "Contributed",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [{
        "indexed": false,
        "internalType": "uint256",
        "name": "liquidity",
        "type": "uint256"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "timestamp",
        "type": "uint256"
      }
    ],
    "name": "Finalized",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [{
        "indexed": false,
        "internalType": "bool",
        "name": "completed",
        "type": "bool"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "timestamp",
        "type": "uint256"
      }
    ],
    "name": "KycUpdated",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [{
        "indexed": false,
        "internalType": "uint256",
        "name": "amount",
        "type": "uint256"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "timestamp",
        "type": "uint256"
      }
    ],
    "name": "LiquidityWithdrawn",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [{
        "indexed": true,
        "internalType": "address",
        "name": "previousOwner",
        "type": "address"
      },
      {
        "indexed": true,
        "internalType": "address",
        "name": "newOwner",
        "type": "address"
      }
    ],
    "name": "OwnershipTransferred",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [{
      "indexed": false,
      "internalType": "uint256",
      "name": "timestamp",
      "type": "uint256"
    }],
    "name": "PoolUpdated",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [{
        "indexed": false,
        "internalType": "uint256",
        "name": "amount",
        "type": "uint256"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "timestamp",
        "type": "uint256"
      }
    ],
    "name": "VestingTokenWithdrawn",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [{
        "indexed": true,
        "internalType": "address",
        "name": "user",
        "type": "address"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "amount",
        "type": "uint256"
      }
    ],
    "name": "WithdrawnContribution",
    "type": "event"
  },
  {
    "inputs": [{
      "internalType": "address[]",
      "name": "users",
      "type": "address[]"
    }],
    "name": "addWhitelistedUsers",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "cancel",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "claim",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [{
      "internalType": "address",
      "name": "",
      "type": "address"
    }],
    "name": "claimedOf",
    "outputs": [{
      "internalType": "uint256",
      "name": "",
      "type": "uint256"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "completedKyc",
    "outputs": [{
      "internalType": "bool",
      "name": "",
      "type": "bool"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "contribute",
    "outputs": [],
    "stateMutability": "payable",
    "type": "function"
  },
  {
    "inputs": [{
      "internalType": "address",
      "name": "",
      "type": "address"
    }],
    "name": "contributionOf",
    "outputs": [{
      "internalType": "uint256",
      "name": "",
      "type": "uint256"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [{
      "internalType": "uint256",
      "name": "amountInWei",
      "type": "uint256"
    }],
    "name": "convert",
    "outputs": [{
      "internalType": "uint256",
      "name": "",
      "type": "uint256"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "cycle",
    "outputs": [{
      "internalType": "uint256",
      "name": "",
      "type": "uint256"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "cycleReleasePct",
    "outputs": [{
      "internalType": "uint256",
      "name": "",
      "type": "uint256"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [{
        "internalType": "uint256",
        "name": "start",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "end",
        "type": "uint256"
      }
    ],
    "name": "distributePurchasedTokens",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [{
        "internalType": "uint256",
        "name": "start",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "end",
        "type": "uint256"
      }
    ],
    "name": "distributeRefund",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [{
      "internalType": "uint8",
      "name": "distributedType",
      "type": "uint8"
    }],
    "name": "distributionCompleted",
    "outputs": [{
      "internalType": "bool",
      "name": "",
      "type": "bool"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [{
        "internalType": "address payable",
        "name": "to_",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "amount_",
        "type": "uint256"
      }
    ],
    "name": "emergencyWithdraw",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [{
        "internalType": "address",
        "name": "token_",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "to_",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "amount_",
        "type": "uint256"
      }
    ],
    "name": "emergencyWithdraw",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "endTime",
    "outputs": [{
      "internalType": "uint256",
      "name": "",
      "type": "uint256"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "factory",
    "outputs": [{
      "internalType": "address",
      "name": "",
      "type": "address"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "finalize",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "finishTime",
    "outputs": [{
      "internalType": "uint256",
      "name": "",
      "type": "uint256"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [{
      "internalType": "address",
      "name": "user_",
      "type": "address"
    }],
    "name": "getContributionAmount",
    "outputs": [{
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "getContributorCount",
    "outputs": [{
      "internalType": "uint256",
      "name": "",
      "type": "uint256"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [{
        "internalType": "uint256",
        "name": "start",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "end",
        "type": "uint256"
      }
    ],
    "name": "getContributors",
    "outputs": [{
      "internalType": "address[]",
      "name": "",
      "type": "address[]"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "getNumberOfWhitelistedUsers",
    "outputs": [{
      "internalType": "uint256",
      "name": "",
      "type": "uint256"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [{
      "internalType": "uint8",
      "name": "distributedType",
      "type": "uint8"
    }],
    "name": "getUndistributedIndexes",
    "outputs": [{
      "internalType": "uint256[]",
      "name": "",
      "type": "uint256[]"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "getUpdatedState",
    "outputs": [{
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      },
      {
        "internalType": "uint8",
        "name": "",
        "type": "uint8"
      },
      {
        "internalType": "bool",
        "name": "",
        "type": "bool"
      },
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      },
      {
        "internalType": "string",
        "name": "",
        "type": "string"
      },
      {
        "internalType": "bool",
        "name": "",
        "type": "bool"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [{
        "internalType": "uint256",
        "name": "startIndex",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "endIndex",
        "type": "uint256"
      }
    ],
    "name": "getWhitelistedUsers",
    "outputs": [{
      "internalType": "address[]",
      "name": "",
      "type": "address[]"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "governance",
    "outputs": [{
      "internalType": "address",
      "name": "",
      "type": "address"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "hardCap",
    "outputs": [{
      "internalType": "uint256",
      "name": "",
      "type": "uint256"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [{
        "internalType": "address[4]",
        "name": "_addrs",
        "type": "address[4]"
      },
      {
        "internalType": "uint256[2]",
        "name": "_rateSettings",
        "type": "uint256[2]"
      },
      {
        "internalType": "uint256[2]",
        "name": "_contributionSettings",
        "type": "uint256[2]"
      },
      {
        "internalType": "uint256[2]",
        "name": "_capSettings",
        "type": "uint256[2]"
      },
      {
        "internalType": "uint256[3]",
        "name": "_timeSettings",
        "type": "uint256[3]"
      },
      {
        "internalType": "uint256[2]",
        "name": "_feeSettings",
        "type": "uint256[2]"
      },
      {
        "internalType": "bool",
        "name": "_useWhitelisting",
        "type": "bool"
      },
      {
        "internalType": "uint256",
        "name": "_liquidityPercent",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "_refundType",
        "type": "uint256"
      },
      {
        "internalType": "string",
        "name": "_poolDetails",
        "type": "string"
      }
    ],
    "name": "initialize",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [{
        "internalType": "uint256",
        "name": "_vcTgeReleasePct",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "_vcCycleReleasePct",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "_vcCycle",
        "type": "uint256"
      }
    ],
    "name": "initializeContributorVesting",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [{
        "internalType": "uint256",
        "name": "_totalVestingTokens",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "_tgeLockDuration",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "_tgeReleasePct",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "_cycle",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "_cycleReleasePct",
        "type": "uint256"
      }
    ],
    "name": "initializeVesting",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [{
      "internalType": "address",
      "name": "user",
      "type": "address"
    }],
    "name": "isUserWhitelisted",
    "outputs": [{
      "internalType": "bool",
      "name": "",
      "type": "bool"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "liquidityBalance",
    "outputs": [{
      "internalType": "uint256",
      "name": "",
      "type": "uint256"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "liquidityListingRate",
    "outputs": [{
      "internalType": "uint256",
      "name": "",
      "type": "uint256"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "liquidityLockDays",
    "outputs": [{
      "internalType": "uint256",
      "name": "",
      "type": "uint256"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "liquidityPercent",
    "outputs": [{
      "internalType": "uint256",
      "name": "",
      "type": "uint256"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "liquidityUnlockTime",
    "outputs": [{
      "internalType": "uint256",
      "name": "",
      "type": "uint256"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "lockId",
    "outputs": [{
      "internalType": "int256",
      "name": "",
      "type": "int256"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "maxContribution",
    "outputs": [{
      "internalType": "uint256",
      "name": "",
      "type": "uint256"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "minContribution",
    "outputs": [{
      "internalType": "uint256",
      "name": "",
      "type": "uint256"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "owner",
    "outputs": [{
      "internalType": "address",
      "name": "",
      "type": "address"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "poolSettings",
    "outputs": [{
        "internalType": "address",
        "name": "token",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "currency",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "startTime",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "endTime",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "rate",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "softCap",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "hardCap",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "liquidityListingRate",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "liquidityLockDays",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "poolDetails",
    "outputs": [{
      "internalType": "string",
      "name": "",
      "type": "string"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "poolState",
    "outputs": [{
      "internalType": "enum BasePool.PoolState",
      "name": "",
      "type": "uint8"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [{
      "internalType": "address",
      "name": "",
      "type": "address"
    }],
    "name": "purchasedOf",
    "outputs": [{
      "internalType": "uint256",
      "name": "",
      "type": "uint256"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "rate",
    "outputs": [{
      "internalType": "uint256",
      "name": "",
      "type": "uint256"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "refundType",
    "outputs": [{
      "internalType": "uint256",
      "name": "",
      "type": "uint256"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [{
      "internalType": "address",
      "name": "",
      "type": "address"
    }],
    "name": "refundedOf",
    "outputs": [{
      "internalType": "uint256",
      "name": "",
      "type": "uint256"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [{
      "internalType": "address[]",
      "name": "users",
      "type": "address[]"
    }],
    "name": "removeWhitelistedUsers",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "renounceOwnership",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "router",
    "outputs": [{
      "internalType": "address",
      "name": "",
      "type": "address"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [{
      "internalType": "address",
      "name": "governance_",
      "type": "address"
    }],
    "name": "setGovernance",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [{
      "internalType": "bool",
      "name": "use",
      "type": "bool"
    }],
    "name": "setUseWhitelisting",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "softCap",
    "outputs": [{
      "internalType": "uint256",
      "name": "",
      "type": "uint256"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "startTime",
    "outputs": [{
      "internalType": "uint256",
      "name": "",
      "type": "uint256"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "tgeLockDuration",
    "outputs": [{
      "internalType": "uint256",
      "name": "",
      "type": "uint256"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "tgeReleasePct",
    "outputs": [{
      "internalType": "uint256",
      "name": "",
      "type": "uint256"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "tgeTime",
    "outputs": [{
      "internalType": "uint256",
      "name": "",
      "type": "uint256"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "token",
    "outputs": [{
      "internalType": "address",
      "name": "",
      "type": "address"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "totalClaimed",
    "outputs": [{
      "internalType": "uint256",
      "name": "",
      "type": "uint256"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "totalRaised",
    "outputs": [{
      "internalType": "uint256",
      "name": "",
      "type": "uint256"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "totalRefunded",
    "outputs": [{
      "internalType": "uint256",
      "name": "",
      "type": "uint256"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "totalVestedTokens",
    "outputs": [{
      "internalType": "uint256",
      "name": "",
      "type": "uint256"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "totalVestingTokens",
    "outputs": [{
      "internalType": "uint256",
      "name": "",
      "type": "uint256"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "totalVolumePurchased",
    "outputs": [{
      "internalType": "uint256",
      "name": "",
      "type": "uint256"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [{
      "internalType": "address",
      "name": "newOwner",
      "type": "address"
    }],
    "name": "transferOwnership",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [{
      "internalType": "bool",
      "name": "completed_",
      "type": "bool"
    }],
    "name": "updateCompletedKyc",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [{
      "internalType": "string",
      "name": "details_",
      "type": "string"
    }],
    "name": "updatePoolDetails",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "useWhitelisting",
    "outputs": [{
      "internalType": "bool",
      "name": "",
      "type": "bool"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "vcCycle",
    "outputs": [{
      "internalType": "uint256",
      "name": "",
      "type": "uint256"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "vcCycleReleasePct",
    "outputs": [{
      "internalType": "uint256",
      "name": "",
      "type": "uint256"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "vcTgeReleasePct",
    "outputs": [{
      "internalType": "uint256",
      "name": "",
      "type": "uint256"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "version",
    "outputs": [{
      "internalType": "uint8",
      "name": "",
      "type": "uint8"
    }],
    "stateMutability": "pure",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "withdrawCancelledTokens",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "withdrawContribution",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "withdrawVestingToken",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [{
      "internalType": "address",
      "name": "user",
      "type": "address"
    }],
    "name": "withdrawableContributorVestingTokens",
    "outputs": [{
      "internalType": "uint256",
      "name": "",
      "type": "uint256"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "withdrawableTokens",
    "outputs": [{
      "internalType": "uint256",
      "name": "",
      "type": "uint256"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "stateMutability": "payable",
    "type": "receive"
  }
]
export const DxSale_ABI = [{
    "inputs": [{
      "internalType": "uint256",
      "name": "",
      "type": "uint256"
    }],
    "name": "presaleOwners",
    "outputs": [{
      "internalType": "address",
      "name": "",
      "type": "address"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [{
      "internalType": "address",
      "name": "",
      "type": "address"
    }],
    "name": "presales",
    "outputs": [{
        "internalType": "bool",
        "name": "exists",
        "type": "bool"
      },
      {
        "internalType": "uint256",
        "name": "createdOn",
        "type": "uint256"
      },
      {
        "internalType": "address",
        "name": "presaleInfoAddr",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "tokenAddress",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "presaleAddress",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "governor",
        "type": "address"
      },
      {
        "internalType": "bool",
        "name": "active",
        "type": "bool"
      },
      {
        "internalType": "uint256",
        "name": "startTime",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "endTime",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "govPercentage",
        "type": "uint256"
      },
      {
        "internalType": "address",
        "name": "uniswapDep",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "uniswapPercentage",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "uniswapRate",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "lp_locked",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  }
]
export const PinkSaleTest_ABI = [{
    "anonymous": false,
    "inputs": [{
      "indexed": false,
      "internalType": "uint256",
      "name": "timestamp",
      "type": "uint256"
    }],
    "name": "Cancelled",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [{
        "indexed": true,
        "internalType": "address",
        "name": "user",
        "type": "address"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "volume",
        "type": "uint256"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "total",
        "type": "uint256"
      }
    ],
    "name": "Claimed",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [{
        "indexed": true,
        "internalType": "address",
        "name": "user",
        "type": "address"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "amount",
        "type": "uint256"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "volume",
        "type": "uint256"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "total",
        "type": "uint256"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "timestamp",
        "type": "uint256"
      }
    ],
    "name": "Contributed",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [{
        "indexed": false,
        "internalType": "uint256",
        "name": "liquidity",
        "type": "uint256"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "timestamp",
        "type": "uint256"
      }
    ],
    "name": "Finalized",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [{
        "indexed": false,
        "internalType": "bool",
        "name": "completed",
        "type": "bool"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "timestamp",
        "type": "uint256"
      }
    ],
    "name": "KycUpdated",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [{
        "indexed": false,
        "internalType": "uint256",
        "name": "amount",
        "type": "uint256"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "timestamp",
        "type": "uint256"
      }
    ],
    "name": "LiquidityWithdrawn",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [{
        "indexed": true,
        "internalType": "address",
        "name": "previousOwner",
        "type": "address"
      },
      {
        "indexed": true,
        "internalType": "address",
        "name": "newOwner",
        "type": "address"
      }
    ],
    "name": "OwnershipTransferred",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [{
      "indexed": false,
      "internalType": "uint256",
      "name": "timestamp",
      "type": "uint256"
    }],
    "name": "PoolUpdated",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [{
        "indexed": false,
        "internalType": "uint256",
        "name": "amount",
        "type": "uint256"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "timestamp",
        "type": "uint256"
      }
    ],
    "name": "VestingTokenWithdrawn",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [{
        "indexed": true,
        "internalType": "address",
        "name": "user",
        "type": "address"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "amount",
        "type": "uint256"
      }
    ],
    "name": "WithdrawnContribution",
    "type": "event"
  },
  {
    "inputs": [{
      "internalType": "address[]",
      "name": "users",
      "type": "address[]"
    }],
    "name": "addWhitelistedUsers",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "cancel",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "claim",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [{
      "internalType": "address",
      "name": "",
      "type": "address"
    }],
    "name": "claimedOf",
    "outputs": [{
      "internalType": "uint256",
      "name": "",
      "type": "uint256"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "completedKyc",
    "outputs": [{
      "internalType": "bool",
      "name": "",
      "type": "bool"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "contribute",
    "outputs": [],
    "stateMutability": "payable",
    "type": "function"
  },
  {
    "inputs": [{
      "internalType": "address",
      "name": "",
      "type": "address"
    }],
    "name": "contributionOf",
    "outputs": [{
      "internalType": "uint256",
      "name": "",
      "type": "uint256"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [{
      "internalType": "uint256",
      "name": "amountInWei",
      "type": "uint256"
    }],
    "name": "convert",
    "outputs": [{
      "internalType": "uint256",
      "name": "",
      "type": "uint256"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "cycle",
    "outputs": [{
      "internalType": "uint256",
      "name": "",
      "type": "uint256"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "cycleReleasePct",
    "outputs": [{
      "internalType": "uint256",
      "name": "",
      "type": "uint256"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [{
        "internalType": "uint256",
        "name": "start",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "end",
        "type": "uint256"
      }
    ],
    "name": "distributePurchasedTokens",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [{
        "internalType": "uint256",
        "name": "start",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "end",
        "type": "uint256"
      }
    ],
    "name": "distributeRefund",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [{
      "internalType": "uint8",
      "name": "distributedType",
      "type": "uint8"
    }],
    "name": "distributionCompleted",
    "outputs": [{
      "internalType": "bool",
      "name": "",
      "type": "bool"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [{
        "internalType": "address payable",
        "name": "to_",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "amount_",
        "type": "uint256"
      }
    ],
    "name": "emergencyWithdraw",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [{
        "internalType": "address",
        "name": "token_",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "to_",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "amount_",
        "type": "uint256"
      }
    ],
    "name": "emergencyWithdraw",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "endTime",
    "outputs": [{
      "internalType": "uint256",
      "name": "",
      "type": "uint256"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "factory",
    "outputs": [{
      "internalType": "address",
      "name": "",
      "type": "address"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "finalize",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "finishTime",
    "outputs": [{
      "internalType": "uint256",
      "name": "",
      "type": "uint256"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [{
      "internalType": "address",
      "name": "user_",
      "type": "address"
    }],
    "name": "getContributionAmount",
    "outputs": [{
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "getContributorCount",
    "outputs": [{
      "internalType": "uint256",
      "name": "",
      "type": "uint256"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [{
        "internalType": "uint256",
        "name": "start",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "end",
        "type": "uint256"
      }
    ],
    "name": "getContributors",
    "outputs": [{
      "internalType": "address[]",
      "name": "",
      "type": "address[]"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "getNumberOfWhitelistedUsers",
    "outputs": [{
      "internalType": "uint256",
      "name": "",
      "type": "uint256"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [{
      "internalType": "uint8",
      "name": "distributedType",
      "type": "uint8"
    }],
    "name": "getUndistributedIndexes",
    "outputs": [{
      "internalType": "uint256[]",
      "name": "",
      "type": "uint256[]"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "getUpdatedState",
    "outputs": [{
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      },
      {
        "internalType": "uint8",
        "name": "",
        "type": "uint8"
      },
      {
        "internalType": "bool",
        "name": "",
        "type": "bool"
      },
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      },
      {
        "internalType": "string",
        "name": "",
        "type": "string"
      },
      {
        "internalType": "bool",
        "name": "",
        "type": "bool"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [{
        "internalType": "uint256",
        "name": "startIndex",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "endIndex",
        "type": "uint256"
      }
    ],
    "name": "getWhitelistedUsers",
    "outputs": [{
      "internalType": "address[]",
      "name": "",
      "type": "address[]"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "governance",
    "outputs": [{
      "internalType": "address",
      "name": "",
      "type": "address"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "hardCap",
    "outputs": [{
      "internalType": "uint256",
      "name": "",
      "type": "uint256"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [{
        "internalType": "address[4]",
        "name": "_addrs",
        "type": "address[4]"
      },
      {
        "internalType": "uint256[2]",
        "name": "_rateSettings",
        "type": "uint256[2]"
      },
      {
        "internalType": "uint256[2]",
        "name": "_contributionSettings",
        "type": "uint256[2]"
      },
      {
        "internalType": "uint256[2]",
        "name": "_capSettings",
        "type": "uint256[2]"
      },
      {
        "internalType": "uint256[3]",
        "name": "_timeSettings",
        "type": "uint256[3]"
      },
      {
        "internalType": "uint256[2]",
        "name": "_feeSettings",
        "type": "uint256[2]"
      },
      {
        "internalType": "bool",
        "name": "_useWhitelisting",
        "type": "bool"
      },
      {
        "internalType": "uint256",
        "name": "_liquidityPercent",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "_refundType",
        "type": "uint256"
      },
      {
        "internalType": "string",
        "name": "_poolDetails",
        "type": "string"
      }
    ],
    "name": "initialize",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [{
        "internalType": "uint256",
        "name": "_vcTgeReleasePct",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "_vcCycleReleasePct",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "_vcCycle",
        "type": "uint256"
      }
    ],
    "name": "initializeContributorVesting",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [{
        "internalType": "uint256",
        "name": "_totalVestingTokens",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "_tgeLockDuration",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "_tgeReleasePct",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "_cycle",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "_cycleReleasePct",
        "type": "uint256"
      }
    ],
    "name": "initializeVesting",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [{
      "internalType": "address",
      "name": "user",
      "type": "address"
    }],
    "name": "isUserWhitelisted",
    "outputs": [{
      "internalType": "bool",
      "name": "",
      "type": "bool"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "liquidityBalance",
    "outputs": [{
      "internalType": "uint256",
      "name": "",
      "type": "uint256"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "liquidityListingRate",
    "outputs": [{
      "internalType": "uint256",
      "name": "",
      "type": "uint256"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "liquidityLockDays",
    "outputs": [{
      "internalType": "uint256",
      "name": "",
      "type": "uint256"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "liquidityPercent",
    "outputs": [{
      "internalType": "uint256",
      "name": "",
      "type": "uint256"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "liquidityUnlockTime",
    "outputs": [{
      "internalType": "uint256",
      "name": "",
      "type": "uint256"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "lockId",
    "outputs": [{
      "internalType": "int256",
      "name": "",
      "type": "int256"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "maxContribution",
    "outputs": [{
      "internalType": "uint256",
      "name": "",
      "type": "uint256"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "minContribution",
    "outputs": [{
      "internalType": "uint256",
      "name": "",
      "type": "uint256"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "owner",
    "outputs": [{
      "internalType": "address",
      "name": "",
      "type": "address"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "poolSettings",
    "outputs": [{
        "internalType": "address",
        "name": "token",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "currency",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "startTime",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "endTime",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "rate",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "softCap",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "hardCap",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "liquidityListingRate",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "liquidityLockDays",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "poolDetails",
    "outputs": [{
      "internalType": "string",
      "name": "",
      "type": "string"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "poolState",
    "outputs": [{
      "internalType": "enum BasePool.PoolState",
      "name": "",
      "type": "uint8"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [{
      "internalType": "address",
      "name": "",
      "type": "address"
    }],
    "name": "purchasedOf",
    "outputs": [{
      "internalType": "uint256",
      "name": "",
      "type": "uint256"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "rate",
    "outputs": [{
      "internalType": "uint256",
      "name": "",
      "type": "uint256"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "refundType",
    "outputs": [{
      "internalType": "uint256",
      "name": "",
      "type": "uint256"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [{
      "internalType": "address",
      "name": "",
      "type": "address"
    }],
    "name": "refundedOf",
    "outputs": [{
      "internalType": "uint256",
      "name": "",
      "type": "uint256"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [{
      "internalType": "address[]",
      "name": "users",
      "type": "address[]"
    }],
    "name": "removeWhitelistedUsers",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "renounceOwnership",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "router",
    "outputs": [{
      "internalType": "address",
      "name": "",
      "type": "address"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [{
      "internalType": "address",
      "name": "governance_",
      "type": "address"
    }],
    "name": "setGovernance",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [{
      "internalType": "bool",
      "name": "use",
      "type": "bool"
    }],
    "name": "setUseWhitelisting",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "softCap",
    "outputs": [{
      "internalType": "uint256",
      "name": "",
      "type": "uint256"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "startTime",
    "outputs": [{
      "internalType": "uint256",
      "name": "",
      "type": "uint256"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "tgeLockDuration",
    "outputs": [{
      "internalType": "uint256",
      "name": "",
      "type": "uint256"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "tgeReleasePct",
    "outputs": [{
      "internalType": "uint256",
      "name": "",
      "type": "uint256"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "tgeTime",
    "outputs": [{
      "internalType": "uint256",
      "name": "",
      "type": "uint256"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "token",
    "outputs": [{
      "internalType": "address",
      "name": "",
      "type": "address"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "totalClaimed",
    "outputs": [{
      "internalType": "uint256",
      "name": "",
      "type": "uint256"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "totalRaised",
    "outputs": [{
      "internalType": "uint256",
      "name": "",
      "type": "uint256"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "totalRefunded",
    "outputs": [{
      "internalType": "uint256",
      "name": "",
      "type": "uint256"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "totalVestedTokens",
    "outputs": [{
      "internalType": "uint256",
      "name": "",
      "type": "uint256"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "totalVestingTokens",
    "outputs": [{
      "internalType": "uint256",
      "name": "",
      "type": "uint256"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "totalVolumePurchased",
    "outputs": [{
      "internalType": "uint256",
      "name": "",
      "type": "uint256"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [{
      "internalType": "address",
      "name": "newOwner",
      "type": "address"
    }],
    "name": "transferOwnership",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [{
      "internalType": "bool",
      "name": "completed_",
      "type": "bool"
    }],
    "name": "updateCompletedKyc",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [{
      "internalType": "string",
      "name": "details_",
      "type": "string"
    }],
    "name": "updatePoolDetails",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "useWhitelisting",
    "outputs": [{
      "internalType": "bool",
      "name": "",
      "type": "bool"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "vcCycle",
    "outputs": [{
      "internalType": "uint256",
      "name": "",
      "type": "uint256"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "vcCycleReleasePct",
    "outputs": [{
      "internalType": "uint256",
      "name": "",
      "type": "uint256"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "vcTgeReleasePct",
    "outputs": [{
      "internalType": "uint256",
      "name": "",
      "type": "uint256"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "version",
    "outputs": [{
      "internalType": "uint8",
      "name": "",
      "type": "uint8"
    }],
    "stateMutability": "pure",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "withdrawCancelledTokens",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "withdrawContribution",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "withdrawVestingToken",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [{
      "internalType": "address",
      "name": "user",
      "type": "address"
    }],
    "name": "withdrawableContributorVestingTokens",
    "outputs": [{
      "internalType": "uint256",
      "name": "",
      "type": "uint256"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "withdrawableTokens",
    "outputs": [{
      "internalType": "uint256",
      "name": "",
      "type": "uint256"
    }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "stateMutability": "payable",
    "type": "receive"
  }
]